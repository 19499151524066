import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import {useStores} from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import MediaDecision from "../containers/MediaDecision";

const useStyles = makeStyles((theme) => ({

    root:{
        width:"100%",
        overflow:"auto",
        marginBottom:40

    },
    items:{
        display:"flex"

    },
    colorWhite:{
        color:"white",
    },
    item:{
        marginRight: theme.spacing(2),
    },
    thumb: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(9),
            height: theme.spacing(9),
        },
    },

    lineClamp2: {
        "-webkit-line-clamp": "2 !important"
    },

    lineClamp: {
        margin: "5px 0",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        lineClamp: 2,
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        paddingRight: theme.spacing(2),
    },

    currentItem:{
        color: "#1db954"
    },
    thumbContainer:{
        marginRight: theme.spacing(2),
        position: "relative"
    },

    loadOverlay:{
        position: "absolute",
        top:0,
        bottom:0,
        left:0,
        right:0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }
}));


export default observer(({items, onSelect, onVideOpen, onOpenBook, onAudioPlay, onAudioPause }) => {
    const classes = useStyles();
    const { audioStore, libraryStore } = useStores();
    const { detailsLoadingFor } = libraryStore;
    const { audioPaused, mediaID: currentMediaID } = audioStore;
    const [media, setMedia] = React.useState(null);



    useEffect( () => {
        if ( !audioStore.mediaID ) {
            setMedia(null);
        }
    }, [audioStore.mediaID ]);



    const handleMediaRowClick = async (media) => {
        setMedia(media);
        if ( audioStore.mediaID  === media.mediaID) {
            if (audioPaused){
                onAudioPlay(media);
            }
            else
                onAudioPause(media);
        }
    };




    return <div className={classes.root}>
        <div className={classes.items}>
            {items.map(s=>{
                return <Fragment key={s.mediaID}>
                        <div className={classes.item}>
                            <div className={classes.thumbContainer}>
                                <Avatar variant="rounded" className={classes.thumb}  onClick={e=>{ handleMediaRowClick(s) }}
                                        alt={` `}
                                        src={`${s.imageURL}`}
                                />
                                {detailsLoadingFor && detailsLoadingFor.includes(s.mediaID) &&
                                <div className={classes.loadOverlay }>
                                    <CircularProgress color={"secondary"} size={70} thickness={4}/>
                                </div>}
                            </div>
                            <span className={clsx(classes.lineClamp, classes.lineClamp2)}>{s.title}</span>
                        </div>
                </Fragment>
            }) }
        </div>
        <MediaDecision
            media={media}
            onSelect={onSelect}
            onVideoOpen={onVideOpen}
            onOpenBook={onOpenBook}
            onAudioPlay={onAudioPlay}
            audioPaused={audioPaused}
            currentMediaID={currentMediaID}
            onAudioPause={onAudioPause}

        />
    </div>
})


