import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faCopy, faShare, } from "@fortawesome/pro-light-svg-icons";
import { useStores } from '../hooks/use-stores';
import { extractErrorMessage } from '../utils/helpers';
import Vimeo from '@u-wave/react-vimeo';

const useStyles = makeStyles((theme) => ({
    qrCodeHelperText: {
        marginTop: `${theme.spacing(3)}px`,
        textAlign: 'center'
    },
    faIcons: {
        marginRight: "5px"
    },
    divider: {
        margin: "20px 0"
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
    card: {
        width: "100%",
    },
    qrCodeSpacing: {
        marginBottom: `${theme.spacing(2)}px`
    },
    enrollmentLink: {
        textAlign: 'center',
        width: '100%'
    },
    generateNewLink: {
        marginTop: '18px'
    },
    preziIframe: {
        width: '100%',
        maxWidth: '800px',
        minHeight: '400px',
    }
}));

const SelectLinkPage = ({ loading, hasAccess, links, header, description, generateShareLink, handleSelectionChange, selectionError, selection }) => {
    const classes = useStyles();

    return (
        <>
            <h2>{header}</h2>
            <h4>{description}</h4>
            <FormControl fullWidth className={classes.margin} variant="outlined" error={Boolean(selectionError)}>
                <InputLabel htmlFor="age-native-simple">Please Select</InputLabel>
                {links &&
                    links.length > 0 && <Select
                        required
                        placeholder="Please Select"
                        label="Enrollment Link Type:"
                        className={classes.enrollmentLink}
                        onChange={handleSelectionChange}
                        value={selection || ''}>
                        {links.map((val, index) =>
                            <MenuItem key={index} value={val}>{val.text}</MenuItem>
                        )}
                    </Select>
                }
                <FormHelperText>{selectionError}</FormHelperText>
            </FormControl>
            {
                selection?.vimeoId && <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0px' }}>
                    <Vimeo
                        responsive
                        style={{ width: '100%' }}
                        video={selection.vimeoId}
                    />
                </Grid>
            }
            {
                selection?.preziId &&
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0px' }}>
                    <iframe className={classes.preziIframe} src={`https://prezi.com/view/${selection.preziId}`} />
                </Grid>
            }
            <Grid item xs={12} lg={12}>
                <Button
                    fullWidth
                    className={classes.generateNewLink}
                    color="primary"
                    variant="contained"
                    size="large"
                    style={{ color: 'white' }}
                    onClick={generateShareLink}
                    disabled={!!hasAccess ? loading : true}>
                    <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                    Generate New Link
                </Button>
            </Grid>
        </>
    )
}

const CopyLinksPage = ({ loading, shareLink, showQRCode, qrCodeShown, onGenerateNewPage }) => {
    const { t } = useTranslation();
    const { commonStore } = useStores();
    const classes = useStyles();

    return (
        <>
            {
                loading === true
                    ?
                    <>
                        <CircularProgress />
                    </>
                    :
                    !shareLink
                        ? ''
                        :
                        <>
                            <TextField
                                disabled={true}
                                fullWidth
                                variant={"filled"}
                                label={t("starfish:share.shareLink")}
                                value={shareLink} />
                            <br />
                            <br />
                            <br />
                            <br />
                            <Grid container spacing={3} justify={"center"}>
                                <Grid item xs={10} md={7} >
                                    <CopyToClipboard text={shareLink}
                                        onCopy={() => commonStore.success("Link copied")}>
                                        <Button fullWidth color="primary" variant="contained" size="large" style={{ color: 'white' }} disabled={!shareLink}>
                                            <FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" />
                                            {t("starfish:share.copyLink")}
                                        </Button>
                                    </CopyToClipboard>
                                </Grid>
                                <Grid item xs={10} md={7} >
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        style={{ color: 'white' }}
                                        href={`sms:?&body=${shareLink}`}
                                        disabled={!shareLink}>
                                        <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                        {t("starfish:share.textLink")}
                                    </Button>
                                </Grid>
                                <Grid item xs={10} md={7} container justify={"center"}>
                                    <Button
                                        fullWidth
                                        className={classes.qrCodeSpacing}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        style={{ color: 'white' }}
                                        onClick={showQRCode}
                                        disabled={!shareLink}>
                                        <FontAwesomeIcon className={classes.faIcons} icon={faQrcode} size="1x" />
                                        {t("starfish:share.qrCode")}
                                    </Button>
                                    {qrCodeShown
                                        ?
                                        <>
                                            <Grid item xs={12} container justify={"center"}>
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={shareLink}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.qrCodeHelperText}>
                                                    Scan code above to share link
                                                </Typography>
                                            </Grid>
                                        </>
                                        : null}
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid item xs={10} md={7} >
                                    <Button fullWidth
                                        className={classes.generateNewLink}
                                        color="default"
                                        variant="contained"
                                        size="large"
                                        style={{ color: 'black' }}
                                        onClick={onGenerateNewPage}
                                    >
                                        <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                        Generate Another Link
                                    </Button>
                                </Grid>
                                <Divider className={classes.divider} />
                            </Grid>
                        </>
            }
        </>
    );
}

/*
Props:
    links: [{value: '', text: '', vimeoId?: string, preziId?: string }];
    header: string;
    description: string;
    hasAccess: boolean;
    onGenerateLink: (value: string used to generate the embed) => linkUrl)
*/
const ShareLinks = ({ links, onGenerateLink, header, description, hasAccess }) => {
    const classes = useStyles();

    const [shareLink, setShareLink] = useState('');
    const [qrCodeShown, setQRCodeShown] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [selection, setSelection] = React.useState(null);
    const [selectionError, setSelectionError] = React.useState(null);
    const [page, setPage] = React.useState('0');

    const generateShareLink = async () => {
        try {
            if (!selection || selection === '' || !selection.text) {
                setSelectionError('Please select a link type to generate');
                return;
            }
            setError(null);
            setLoading(true);
            setPage('1');

            const response = await onGenerateLink(selection);
            if (response.msgID) {
                setShareLink(`https://www.mainhomepage.com/msg.aspx/${response.msgID}`);
            } else {
                setShareLink(response.url);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setPage('0');
            setError(extractErrorMessage(e));
        }
    };

    const showQRCode = () => {
        setQRCodeShown(!qrCodeShown);
    };

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
        setSelectionError(null);
    }

    return (
        <Container maxWidth={"md"} className={classes.root}>
            <Grid container spacing={3}>
                <Card variant="outlined" className={classes.card}>
                    <CardContent>
                        {error && <Alert severity={"error"}>{error}</Alert>}
                        {
                            page === '0'
                                ?
                                <SelectLinkPage
                                    links={links}
                                    loading={loading}
                                    hasAccess={hasAccess}
                                    header={header}
                                    description={description}
                                    generateShareLink={generateShareLink}
                                    selection={selection}
                                    selectionError={selectionError}
                                    handleSelectionChange={handleSelectionChange}
                                />
                                : null
                        }
                        {
                            page === '1'
                                ?
                                <>
                                    <CopyLinksPage
                                        loading={loading}
                                        shareLink={shareLink}
                                        onGenerateNewPage={() => { setPage('0'); setSelection(null); }}
                                        showQRCode={showQRCode}
                                        qrCodeShown={qrCodeShown}
                                    />
                                </>
                                : null
                        }
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
                {
                    page == '1' && <Grid container style={{ marginTop: '8px' }}>
                        <Grid item xs={12}>
                            <Typography>
                                Texting links across mobile platforms and carriers can sometimes be inconsistent.  You can always use the Copy Link option and send it with an email.
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container>
    );
};

export default ShareLinks;