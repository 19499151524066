import React, {useState} from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import {Check, Close} from "@material-ui/icons";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    pointsUL:{
        margin: 0,
        marginTop: 14,
        padding: 0,
        marginLeft: 50

    },
    ULwithMarin:{
        marginLeft: 150,
        [theme.breakpoints.down(768)]: {
            marginLeft: 50,
        }

    },
    bulletPoint:{
        paddingBottom: 14,
        fontFamily: "'Barlow Condensed', 'Arial'",
        fontSize: 20,
        "&:nth-child(odd) ":{
          "fontWeight": "bold",
        }
    },
    expanded:{
        display:"block"
    },
    button:{
        borderRadius: 20,
        marginTop: 14,
        marginLeft: 14,
        marginRight: 14,
    },

    featuresTable:{
        width:"100%",
        marginLeft:50,
        marginRight:50,
        [theme.breakpoints.down(1024)]: {
            marginLeft: 20,
            marginRight: 20,
        },
        [theme.breakpoints.down(768)]: {
            marginLeft: 5,
            marginRight: 5,
            fontSize: 18
        },
        fontFamily: "'Barlow Condensed', 'Arial'",
        fontSize: 20
    },
    tableHead:{
        "&> th":{
            borderBottom: "1px solid white",
            textAlign: "left",
            padding: 5
        },
        "&> th:not(:first-child) ":{
            textAlign: "center"
        }

    },
    featureRow:{
        "&:nth-child(even) ":{
            backgroundColor: "rgb(51,51,51)",
        },
        "&:nth-child(odd) ":{
            fontWeight: "bold",
        },
        "&> td":{
            padding: 5,
        }
    },
    basicCell:{
        borderLeft: "1px solid white",
        borderRighr: "1px solid white",
        textAlign: "center"
    },
    advancedCell:{
        textAlign: "center"
    }

}));

const features = [
    {key: "feature1", basic: true, advanced: true},
    {key: "feature2", basic: true, advanced: true},
    {key: "feature3", basic: true, advanced: true},
    {key: "feature4", basic: true, advanced: true},
    {key: "feature5", basic: true, advanced: true},
    {key: "feature6", basic: true, advanced: true},
    {key: "feature7", basic: true, advanced: true},
    {key: "feature8", basic: true, advanced: false},
    {key: "feature9", basic: false, advanced: true},
    {key: "feature10", basic: false, advanced: true},
    {key: "feature11", basic: false, advanced: true},
    {key: "feature12", basic: false, advanced: true},
    {key: "feature13", basic: false, advanced: true},
    {key: "feature14", basic: false, advanced: true},
    {key: "feature15", basic: false, advanced: true},
    {key: "feature16", basic: false, advanced: true},
    {key: "feature17", basic: false, advanced: true},
]
function TopGun(){
    const {authStore, videoStore, commonStore} = useStores();
    const classes = useStyles({country:commonStore.language});

    const basicButtonProps = {}
    const advancedButtonProps = {}
    const alreadyHasBasic = ()=>{
        commonStore.showConfirm( "You are currently subscribed to: Basic"
            , "OK"
            , "Already subscribed",  ()=>{}, null );
    }
    const alreadyHasPremium = ()=>{
        commonStore.showConfirm( "You are currently subscribed to: Premium"
            , "OK"
            , "Already subscribed",  ()=>{}, null  );
    }

    if ( authStore.subscriptions.hasLTBasic ) {
        basicButtonProps.onClick = alreadyHasBasic
    }
    else {
        basicButtonProps.target = "_blank"
        basicButtonProps.href = `https://shop.mainhomepage.com/quickcheckout.aspx?login=${authStore.loginGuid}&type=subnew&id=729`
    }

    if ( authStore.subscriptions.hasLTPremium ) {
        advancedButtonProps.onClick = alreadyHasPremium
    }
    else {
        advancedButtonProps.target = "_blank"
        advancedButtonProps.href = `https://shop.mainhomepage.com/quickcheckout.aspx?login=${authStore.loginGuid}&type=subnew&id=766`
    }


    const {t} = useTranslation("starfish");


    const showVideo  = ()=>{
        videoStore.setMedia( {url: "786249505",  vimeo: true } )
    }


    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={ commonStore.language === "fr"
                    ? `${process.env.PUBLIC_URL}/img/marketing_system1-fr.jpg`
                    : `${process.env.PUBLIC_URL}/img/marketing_system1-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">
                    <span className={classes.linkColor}>{t("marketing_system.basic_subcription_prefix")}</span>
                    {t("marketing_system.basic_subcription")}
                    <span className={classes.linkColor}>{t("marketing_system.basic_subcription_suffix")}</span>
                </Typography>
                <Typography align="center" className={classes.headText}  variant="h4">
                    {t("marketing_system.guide_to_business1")}
                    <span className={classes.linkColor}>{t("marketing_system.guide_to_business2")}</span>
                    {t("marketing_system.guide_to_business3")}
                    <span className={classes.linkColor}>{t("marketing_system.guide_to_business4")}</span>
                    {t("marketing_system.guide_to_business5")}
                </Typography>
                <section className={clsx( classes.section)}>
                    {' '}
                </section>
                <Typography align="center"  variant="body1">{t("marketing_system.from_educational_content")}</Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center">

                        <Button color="primary" variant="contained" className={classes.button} {...basicButtonProps} >
                            {t("marketing_system.subscribe_to_basic")}</Button>

                        <Button color="primary" variant="contained"
                                className={classes.button}
                                onClick={showVideo}>
                            {t("marketing_system.watch_video")}</Button>

                </Box>
            </div>
        </section>
        <section className={clsx( classes.section, classes.inverse)}>
            <div className={clsx( classes.textContainer)}>
                    <Typography align="center" className={classes.mainText}  variant="body1">{t("marketing_system.subscriptions_description")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <Grid container spacing={2}>
                <Grid item sm={2} xs ={0} container alignItems="center" ><Divider className={classes.divider} /></Grid>
                <Grid item sm={8}>
                    <Typography align="center" className={classes.headText} variant="h3">
                        <span className={classes.linkColor}>{t("marketing_system.basic_value1")}</span>
                        {t("marketing_system.basic_value2")}
                        <span className={classes.linkColor}>{t("marketing_system.basic_value3")}</span>
                    </Typography>
                </Grid>
                <Grid item sm={2} xs ={0} container alignItems="center" ><Divider className={classes.divider}/></Grid>
           </Grid>
        </section>

        <section className={clsx( classes.section)}>
            <ul className={clsx( classes.pointsUL, classes.ULwithMarin)}>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items1")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items2")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items3")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items4")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items5")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items6")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items7")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items8")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items9")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items10")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.basic_items11")}</li>
            </ul>
        </section>
        <section className={clsx( classes.section)}>
            <Divider className={classes.divider}/>
        </section>

        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">
                    {t("marketing_system.radically_enhance1")}
                    <span className={classes.linkColor}>{t("marketing_system.radically_enhance2")}</span>
                </Typography>
                <Box display="flex" justifyContent="center" >
                    <Button color="primary" variant="contained" className={classes.button} {...basicButtonProps} >
                        {t("marketing_system.subscribe_to_basic")}</Button>
                </Box>
            </div>
        </section>
        <section className={clsx( classes.section, classes.noBottomMargin)}>
            <img className={classes.middleImage}
                 src={ commonStore.language === "fr"
                     ? `${process.env.PUBLIC_URL}/img/marketing_system2-fr.jpg`
                     : `${process.env.PUBLIC_URL}/img/marketing_system2-en.jpg` }
                 role="img"/>
        </section>


        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">
                    {t("marketing_system.basic_price_prefix")}
                    <span className={classes.linkColor}>{t("marketing_system.basic_price")}</span>
                    {t("marketing_system.basic_price_suffix")}
                </Typography>
                <section className={clsx( classes.section)}>
                    {' '}
                </section>
                <Typography align="center"  variant="body1">{t("marketing_system.from_educational_content")}</Typography>
                <Box display="flex" justifyContent="center" >
                    <Button color="primary" variant="contained" className={classes.button} {...advancedButtonProps} >
                        {t("marketing_system.subscribe_to_premium")}</Button>
                </Box>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <Grid container spacing={2}>
                <Grid item sm={2} xs ={0} container alignItems="center" ><Divider className={classes.divider} /></Grid>
                <Grid item sm={8} container alignItems="center" justifyContent="center">
                    <Typography align="center" className={classes.headText} variant="h3">
                        <span className={classes.linkColor}>{t("marketing_system.aditional_value1")}</span>
                        {t("marketing_system.aditional_value2")}
                        <span className={classes.linkColor}>{t("marketing_system.aditional_value3")}</span>
                    </Typography>
                </Grid>
                <Grid item sm={2} xs ={0} container alignItems="center" ><Divider className={classes.divider}/></Grid>
            </Grid>
        </section>

        <section className={clsx( classes.section)}>
            <ul className={clsx( classes.pointsUL)}>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items1")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items2")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items3")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items4")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items5")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items6")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items7")}</li>
                <li className={classes.bulletPoint}>{t("marketing_system.aditional_items8")}</li>
            </ul>
        </section>

        <section className={clsx( classes.section, classes.inverse)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText}  variant="h5">{t("marketing_system.advanced_price_pretext")}</Typography>
                <Typography align="center" className={classes.headText} variant="h3">
                    {t("marketing_system.advanced_price_prefix")}
                    {t("marketing_system.advanced_price")}
                    {t("marketing_system.advanced_price_suffix")}
                </Typography>
                <Box display="flex" justifyContent="center" >
                    <Button color="primary" variant="contained" className={classes.button} {...advancedButtonProps} style={{border:"1px solid white"}} >
                        {t("marketing_system.subscribe_to_premium")}</Button>
                </Box>
            </div>

        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">
                    <span className={classes.linkColor}>{t("marketing_system.basic_vs_premium1")}</span>
                    {t("marketing_system.basic_vs_premium2")}
                </Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
        <table className={classes.featuresTable} cellPadding="0" cellSpacing="0">
            <thead>
            <tr className={classes.tableHead}>
                <th>{t("marketing_system.features")}</th>
                <th>{t("marketing_system.basic")}</th>
                <th>{t("marketing_system.premium")}</th>
            </tr>
            </thead>
            <tbody>
            { features.map( (feature) => (
                <tr  className={classes.featureRow} key={feature.key}>
                    <td>{t("marketing_system."+ feature.key )}</td>
                    <td className={classes.basicCell}>{feature.basic? <Check/>: <Close/> }</td>
                    <td  className={classes.advancedCell}>{feature.advanced? <Check/>: <Close/> }</td>
                </tr>
                ))}
            </tbody>
        </table>
        </section>


        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    <Button color="primary" variant="contained" className={classes.button} {...basicButtonProps} >
                        {t("marketing_system.subscribe_to_basic")}</Button>

                    <Button color="primary" variant="contained" className={classes.button} {...advancedButtonProps} >
                        {t("marketing_system.subscribe_to_premium")}</Button>

                </Box>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            {' '}
        </section>

    </div>
}

export default TopGun