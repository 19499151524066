import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Avatar, Box, IconButton, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '60px'
    },
    gridItem: {
        '&:last-child': {
            paddingRight: 0,
        },
    },
    walletBalance: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        padding: theme.spacing(2),
        minHeight: '100%',
    },
    walletHeader: {
        fontWeight: 'bold',
        fontSize: '.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        }
    },
    blueBorder: {
        borderRadius: 20,
        border: "1px solid #007BFF",
    },
    menuItem: {
        cursor: 'pointer',
        marginTop: theme.spacing(2),
        display: "flex",
        padding: "15px 0 15px 15px",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: 105,
        color: "white",
        flexGrow: 1,
        textDecoration: "none",
        "&:hover": {
            color: "white",
            textDecoration: "none",
        }
    },
    verticalLeftGrowBlock: {
        display: "flex",
        alignItems: "start",
        flexGrow: 1,
        flexDirection: "column",
    },
    menuTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold'
    },
    menuSubtitle: {
        color: 'rgb(109,109,109)'
    }
}));

export const MenuItem = ({ title, subtitle, onClick }) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.menuItem, classes.blueBorder)} onClick={onClick}>
            <ListItemText primary={title} secondary={subtitle} />
        </Box>
    );
};

export const WalletBalance = ({ headerText, subtitleText }) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.walletBalance, classes.blueBorder)}>
            <Box>
                <Typography variant="h6" className={classes.walletHeader}>{headerText}</Typography>
            </Box>
            <Box>
                <Typography variant="body1">{subtitleText}</Typography>
            </Box>
        </Box>
    );
};

export const BalanceMenuItem = ({ link, title, prefix, balance }) => {
    const classes = useStyles();

    return (
        <Box component={Link} to={link} className={clsx(classes.menuItem, classes.blueBorder)}>
            <div className={clsx([classes.verticalLeftGrowBlock])}>
                <Typography variant={"subtitle1"}>{title}</Typography>
                <Typography variant={"caption"}>{"Available balance"}</Typography>
            </div>
            <div style={{paddingRight: 20}}>{prefix}{balance}</div>
        </Box>
    );
}
