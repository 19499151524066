import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { Box, Card, CircularProgress, Container, Tab, makeStyles } from "@material-ui/core";
import { useStores } from "../../../hooks/use-stores";
import services from '../../../services';
import SectionList from "../../../components/convention/SectionList";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        overflow: 'auto',
        backgroundColor: 'black',
        justifyContent: "center",
        maxWidth: '100%',
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    progressContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
    },
    tabPanel: {
        padding: 0,
    },
    tab: {
        '&.MuiButtonBase-root': {
            backgroundColor: '#ce1f42'
        },
        '&.MuiTab-root': {
            flexGrow: 1,
            backgroundColor: 'grey',
            maxWidth: 'initial'
        },
        '&.MuiTabs-indicator': {
            display: "none"
        }
    },
    information: {
        width: '100%',
        backgroundColor: '#000000e6',
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.up('sm')]: {
            maxWidth: "1024px",
        }
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorButton: {
        marginTop: theme.spacing(2),
        color: 'black',
        width: 'max-content',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        }
    }
}));


const SeminarWebinar = () => {
    const { commonStore, seminarWebinarStore } = useStores();
    const { t } = useTranslation();
    const classes = useStyles();
    const params = useParams();
    const date = params && params.year && params.month ? (`${params.year}/${params.month}`) : (`${'2022'}/${'05'}`); //default to 2022/01

    const [selectedTab, setSelectedTab] = useState(commonStore.language );
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const onTabSelected = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const keyToLanguage = (key) => {
        const keyToLanguage = {
            'en': t('conventionProgram:content.english'),
            'es': t('conventionProgram:content.spanish'),
            'fr': t('conventionProgram:content.french')
        };

        return keyToLanguage[key];
    };

    const setLanguage = (language) => {
        commonStore.setLanguage(language, true);
    };

    const loadData = async () => {
        setError(null);
        setLoading(true);

        try {
            const data = await services.SeminarWebinarService.fetchInfo(date);
            seminarWebinarStore.data = data;

            const details = await services.SeminarWebinarService.fetchDetails(date);
            seminarWebinarStore.details = details;
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await loadData();
        })();
    }, []);

    const currentMonth = params && params.month ? parseInt(params.month - 1) : '';
    const month = moment().month(currentMonth).format('MMMM');

    return (
        <Container className={classes.container}>
            {loading ? (
                <Box className={classes.progressContainer}>
                    <CircularProgress />
                </Box>
            ) :
                (error || !seminarWebinarStore.data || !seminarWebinarStore.details) ? <Alert severity="error">{error ? t('seminarWebinar:error.failed') : t('seminarWebinar:error.notFound')}</Alert> :
                    <TabContext value={selectedTab}>
                        <Box width="100%">
                            <TabList className={classes.tabsList} onChange={onTabSelected} >
                                {
                                    Object.keys(seminarWebinarStore.details).map(key => {
                                        return <Tab onClick={() => setLanguage(key)} className={classes.tab} label={keyToLanguage(key)} value={key} key={key} />;
                                    })
                                }
                            </TabList>
                            {Object.keys(seminarWebinarStore.details).map(key => {
                                const section = seminarWebinarStore.details[key];

                                return (
                                    <TabPanel className={classes.tabPanel} value={key} key={key}>
                                        <Card className={classes.information}>
                                            <SectionList
                                                info={section.eventMediaDetail}
                                                hasVideoPermissions={seminarWebinarStore.data.hasStandardTicket}
                                                showEmptyVideoSection={true}
                                                emptyVideoSectionTitle={t('seminarWebinar:content.comingSoonTitle', { month, year: moment().year(), lang: keyToLanguage(key) })}
                                            />
                                        </Card>
                                    </TabPanel>
                                );
                            })}
                        </Box>
                    </TabContext>
            }
        </Container>
    );
};

export default SeminarWebinar;
