import React, { useCallback, useEffect } from 'react';
import { useParams, useRouteMatch } from "react-router";
import { Redirect, Link as RouterLink } from 'react-router-dom';
import ProductList from '../../../containers/shop/ProductList/ProductList';
import CustomCarousel from '../../../containers/shop/Carousel/CustomCarousel';
import LoadingIndicator from '../LoadingIndicator';
import { useStores } from "../../../hooks/use-stores";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { observer, useLocalObservable } from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import uniqBy from "lodash/uniqBy";
import { joinPath } from "../../../utils/helpers";

function ProductCategory({ autoship, categoryId, shoppingRoot }) {

    // constructor(props) {
    //   super(props);
    //
    //   this.state = {
    //     categoryItems: [],
    //     parentCategory: '',
    //     childCategories: [],
    //     showFooter: false
    //   }
    // }

    const match = useRouteMatch();
    const { t } = useTranslation();
    const { authStore, shopStore, commonStore } = useStores();
    const { isGuest, currentUser } = authStore;
    const { language } = commonStore;
    const { parentCategoryIds, productsByCategoryIdLoading, productsByCategory, customerLevelID, market } = shopStore;

    categoryId = parseInt(categoryId, 10);

    const localStore = useLocalObservable(() => ({
        categoryId: categoryId,

        get parentCategory() {
            if (!parentCategoryIds) return null;
            return parentCategoryIds.find((category) => {
                return category.parentCategoryID === this.categoryId;
            });
        },
        get childCategories() {
            return this.parentCategory && this.parentCategory.childCategories;
        },

        get autoshipCategories() {
            if (autoship && productsByCategory && !categoryId) {
                return uniqBy(productsByCategory.map(x => ({ name: x.productType, id: x.productCategoryID })), "id");
            }
            else
                return [];
        },

        get effectiveItems() {
            if (autoship && categoryId) {
                return productsByCategory.filter((item) => {
                    return item.productCategoryID == categoryId;
                });
            }
            else
                return productsByCategory;
        },


        get categories() {
            if (productsByCategory && this.childCategories) {
                return this.childCategories.filter((childCategory) => {
                    const id = productsByCategory.filter((item) => {
                        return item.productCategoryID === childCategory.childCategoryID;
                    });
                    if (!id.length < 1) {
                        return childCategory;
                    }
                    return '';
                });
            } else
                return [];
        }
    }));


    useEffect(() => {
        //localStore.setCategoryId(categoryId)
        (async () => {
            if (categoryId && !autoship )
                await shopStore.getProductsByCategoryId({
                    categoryId
                    , customerLevelID, marketID: market
                    , language, autoship
                });
            else if (autoship) {
                await shopStore.getAutoshipProducts({
                    categoryId
                    , language
                });
            }
            window.scrollTo(0, 0);
        })();
    }, [categoryId, autoship]);




    const filterCategoryItemsById = useCallback((childCategoryID) => {
        if (productsByCategory && productsByCategory.length > 0) {
            const items = productsByCategory.filter((item) => {
                return item.productCategoryID === childCategoryID;
            });
            return items;
        }
    }, [productsByCategory]);

    const filterAutoshipCategory = useCallback((id) => {
        if (productsByCategory && productsByCategory.length > 0) {
            const items = productsByCategory.filter((item) => {
                return item.productCategoryID === id;
            });
            return items;
        }
    }, [productsByCategory]);

    return (isGuest || currentUser.IsMember === '0') && ((localStore.parentCategory === "salesaid") || (localStore.parentCategory === "lifetraining")) ? (
        <Redirect
            to={{
                pathname: '/',
            }}
        />
    ) : (
        <>
            {productsByCategoryIdLoading ?
                (<LoadingIndicator />) :
                (<Container maxWidth={"md"}>
                    {localStore.categories.length > 1 && localStore.parentCategory !== 'subscription' ? (
                        localStore.categories.map((childCategory, i) => {
                            return (
                                <Grid container key={i} direction={"column"}>
                                    <Grid container item justify={"space-between"}>
                                        <Typography variant={"h6"}>{t(`shopping:subcategories.${childCategory.childCategoryName}`)}</Typography>
                                        <Link component={RouterLink}
                                            to={`${match.url}/category/${categoryId}/sub-category/${childCategory.childCategoryID}`}>
                                            {t('shopping:subcategories.seeall')}</Link>
                                    </Grid>
                                    <CustomCarousel
                                        autoship={autoship}
                                        shoppingRoot={shoppingRoot}
                                        parentCategoryID={categoryId}
                                        parentRouteMatch={match}
                                        products={filterCategoryItemsById(childCategory.childCategoryID)}
                                    />
                                </Grid>
                            );
                        })
                    ) : (
                        localStore.autoshipCategories.length > 0
                            ? localStore.autoshipCategories.map((childCategory, i) => {
                                return (
                                    <Grid container key={i} direction={"column"}>
                                        <Grid container item justify={"space-between"}>
                                            <Typography variant={"h6"}>{t(`shopping:subcategories.${childCategory.name}`)}</Typography>
                                            <Link component={RouterLink}
                                                to={joinPath(match.url, (autoship ? "category" : "sub-category"), childCategory.id)}>
                                                {t('shopping:subcategories.seeall')}</Link>
                                        </Grid>
                                        <CustomCarousel
                                            autoship={autoship}
                                            shoppingRoot={shoppingRoot}
                                            parentRouteMatch={match}
                                            parentCategoryID={categoryId}
                                            products={filterAutoshipCategory(childCategory.id)}
                                        />
                                    </Grid>
                                );
                            })
                            : (<div >
                                <Grid container spacing={2}>
                                    <ProductList
                                        autoship={autoship}
                                        parentRouteMatch={match}
                                        category={'category-view-img'}
                                        products={localStore.effectiveItems}
                                        categoryId={categoryId}
                                    />
                                </Grid>
                            </div>)

                    )
                    }
                </Container>)
            }
        </>
    );
}

export default observer(ProductCategory);