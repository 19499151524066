import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
    CircularProgress,
    Container,
    makeStyles
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import services from '../../../services';
import { useStores } from '../../../hooks/use-stores';
import ShareLinks from '../../../components/ShareLinks';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
}));

const GenericSharePage = () => {
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { authStore, } = useStores();
    const classes = useStyles();
    const { currentUser } = authStore;
    const { isSuperApp, isSuperAppPlus, isStarfish } = currentUser;

    const params = useParams();
    const { pageId } = params;

    const getTitle = (value) => {
        if (!pageData || pageData.links == null) {
            return null;
        }

        const videoInfo = pageData.links.find(link => link.value == value)
        return videoInfo.text ?? "-"
    }

    const loadLinks = async () => {
        try {
            let linksLoader;
            let header, description, generateLink;
            switch (pageId) {
                case 'starfishVideos':
                    linksLoader = () => services.Share.getStarfishShareVideos();
                    generateLink = (selection) => services.Share.generateVideoShareLinks(selection.vimeoId, selection.text)
                    header = 'Share Starfish Video';
                    break;
                case 'openMeeting':
                    linksLoader = () => services.Share.getShareLinksByType('O');
                    generateLink = (selection) => services.Share.generateVideoShareLinks(selection.vimeoId, selection.text)
                    header = 'Share Open Meeting Invite';
                    break;
                case 'marketing':
                    linksLoader = () => services.Share.getShareLinksByType('V');
                    generateLink = (selection) => services.Share.generateVideoShareLinks(selection.vimeoId, selection.text)
                    header = 'Share Marketing Videos';
                    break;
                case 'prezi':
                    linksLoader = () => services.Share.getShareLinksByType('P');
                    generateLink = (selection) => services.Share.generatePreziLink(selection.value, selection.text);
                    header = 'Share Prezi';
                    break;
                default:
                    throw new Error('No page found for query');
            }

            const links = await linksLoader();
            if (!links || links.length == 0) {
                throw new Error('No links available');
            }

            setPageData({ links, generateLink, header, description });
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false);
        }
    }

    const hasAccess = React.useMemo(() => {
        if (!!isStarfish || !!isSuperApp || !!isSuperAppPlus) {
            return true;
        } else {
            return false;
        }
    }, [isStarfish, isSuperApp, isSuperAppPlus]);

    useEffect(() => {
        loadLinks();
    }, [pageId])

    return (
        <Container maxWidth={"md"} className={classes.root}>
            {
                loading ? null :
                    (error ? <Alert severity="error">An error ocurred while loading your links. Please try again.</Alert> :
                        <ShareLinks
                            hasAccess={hasAccess}
                            links={pageData.links}
                            onGenerateLink={pageData.generateLink}
                            header={pageData.header}
                            description={pageData.description}
                        />
                    )
            }
        </Container>
    );
};

export default GenericSharePage;