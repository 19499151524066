import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import {useHistory} from "react-router-dom";
import {joinPath} from "../../utils/helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    marginBottom:{
        marginBottom: theme.spacing(2)
    },

    linksBlock:({isCA})=>({
        flex: 1,
        [theme.breakpoints.down(940)]: {
           flex: "unset",
        },
    ...(isCA?{"&:first-child": {
            [theme.breakpoints.up(940)]: {
                marginRight: 20
            },

            [theme.breakpoints.down(940)]: {
                paddingBottom: 25
            }
        },
        "&:last-child": {
            [theme.breakpoints.up(940)]: {
                borderLeft: `1px solid white`,
                paddingLeft: 20
            },

            [theme.breakpoints.down(940)]: {
                paddingTop: 25
            }
        }}:{})
    }),
    linkWithButton:{
        padding: 10,
        whiteSpace: "nowrap",
        [theme.breakpoints.down(999)]: {
            whiteSpace: "unset",
        },
        "& a":{
            marginRight: 10
        }
    }

}));


function Policies({parentMatch}){


    const {authStore, commonStore } = useStores();
    const {t} = useTranslation("starfish");

    const isCA = authStore.country === "CA";

    const classes = useStyles({isCA});

    return <div>
        <section className={clsx( classes.sectionDense)}>
            <img
                src={ commonStore.language === "fr"
                    ? `${process.env.PUBLIC_URL}/img/policies.jpg`
                    : `${process.env.PUBLIC_URL}/img/policies.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={classes.textContainer} style={{width:"100%"}} display="flex" alignItems="center" flexDirection="column" >
                <Typography align={"center"} className={clsx( classes.headTextNoTopMargin)} variant="h3">{t("policies_procedures.title_pre")}<span className={classes.linkColor}>{t("policies_procedures.title")}</span>{t("policies_procedures.title_post")}</Typography>
                <Typography align={"center"} className={clsx( classes.mainText )} variant="h6">{t("policies_procedures.description")}</Typography>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            <Box display={"flex"} alignItems="center" flexWrap="wrap" justifyContent={"center"}>
                <Box flexDirection={"column"} alignItems="center" className={classes.linksBlock}>
                    <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_tac_en1.pdf" >{t("policies_procedures.view")}</Button>
                       {t("policies_procedures.policy1")}
                    </Box>
                    <Box display="flex"  alignItems={"center"}  className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_policiesandprocedures_en_V_40.pdf" >{t("policies_procedures.view")}</Button>
                        {t("policies_procedures.policy2")}
                    </Box>
                    <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_returnmaterialauthorizationform_en2.pdf" >{t("policies_procedures.view")}</Button>
                        {t("policies_procedures.policy3")}
                    </Box>
                </Box>
                {isCA && <>
                    <Box flexDirection={"column"} alignItems="center" className={classes.linksBlock}>
                        <Box display="flex"  alignItems={"center"}  className={classes.linkWithButton }>
                            <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_tac_en1.pdf" >{t("policies_procedures.view")}</Button>
                            {t("policies_procedures.policy1_tr")}
                        </Box>
                        <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                            <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_policiesandprocedures_en_V_40.pdf" >{t("policies_procedures.view")}</Button>
                            {t("policies_procedures.policy2_tr")}
                        </Box>
                        <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                            <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_returnmaterialauthorizationform_en2.pdf" >{t("policies_procedures.view")}</Button>
                            {t("policies_procedures.policy3_tr")}
                        </Box>
                    </Box>
                </>}
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default Policies