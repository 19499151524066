import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import {useHistory} from "react-router-dom";
import {joinPath} from "../../utils/helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    marginBottom:{
        marginBottom: theme.spacing(2)
    }

}));


function CompensationPlan({parentMatch}){

    const classes = useStyles();
    const {authStore,commonStore } = useStores();
    const {t} = useTranslation("starfish");


    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={ commonStore.language === "fr"
                    ? `${process.env.PUBLIC_URL}/img/compensation_plan.png`
                    : `${process.env.PUBLIC_URL}/img/compensation_plan.png` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={classes.textContainer} style={{width:"100%"}} display="flex" alignItems="center" flexDirection="column" >
                <Typography align={"center"} className={clsx( classes.headTextNoTopMargin)} variant="h3">{t("compensation.title_pre")}<span className={classes.linkColor}>{t("compensation.title")}</span>{t("compensation.title_post")}</Typography>
                <Typography className={clsx( classes.mainText, classes.marginBottom )} variant="body1">{t("compensation.description")}</Typography>
                <Typography className={clsx( classes.mainText )}  variant="h6">{t("compensation.welcome")}</Typography>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={clsx( classes.textContainer)} display="flex" justifyContent="center"  flexWrap={"wrap"} style={{ gap:10}} >
                <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/us_compensationplan_en.pdf" >{t("compensation.us")}</Button>
                <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/ca_compensationplan_en.pdf" >{t("compensation.ca-en")}</Button>
                <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/ca_compensationplan_fr.pdf">{t("compensation.ca-fr")}</Button>
                <Button variant="contained" color="primary" download target="_blank" href="https://main.secure.footprint.net/forms/au_compensationplan_en.pdf">{t("compensation.au")}</Button>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default CompensationPlan