import React, {  useMemo, } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core';
import menu from '../menu.json';
import LinksMenu from "../LinksMenu";
import GenericSharePage from "./GenericSharePage";

const ShareHome = () => {
    const MainLinks = useMemo(() => {
        const items = menu.find(x => x.tag === "sharestarfish").items
        return items
    }, [menu]);

    return (<LinksMenu
        items={MainLinks}
        showBack={false}
        parentMatch={{ path: "/royalstarfish" }}
        collapsePath
    />
    )
}

const Share = () => {
    return (
        <Container>
            <Switch>
                <Route
                    path={"/royalstarfish/share/sharelinks/:pageId?"}
                    render={() => <GenericSharePage />}
                />
                <Route
                    render={() => <ShareHome />}
                />
            </Switch>
        </Container>
    );
}

export default observer(Share);