import React, { useEffect, useState } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from "../../hooks/use-stores";
import { isArray, joinPath } from "../../utils/helpers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { isAudio } from "../../utils/mediaUtils";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import { Box, Container } from "@material-ui/core";
import zip from "lodash/zip";
import {starFishColor} from "../../components/styles";


const useStyles = makeStyles((theme) => ({
    content: {
        marginBottom: 70,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    root: {
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 10
    },
    banner: {
        width: "100%"
    },

    bannerImg: {
        width: "100%",
        height: "auto"
    },
    description: {
        margin: "50px 0",
        fontWeight: "normal",
        color: "white"
    },

    videoPlayer: ({isStarfish})=>({
        width: 350,
        height: 197,
        border: `1px solid ${isStarfish?starFishColor(theme, null):"red"}`,
        borderRadius: 5,
        [theme.breakpoints.up(520)]: {
            width: 510,
            height: 286,
        },
        [theme.breakpoints.up(720)]: {
            width: 700,
            height: 394,
        }

    }),
    navigation: ({navigation}) => ( {
        padding: 20,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        position: navigation==="sticky"?"fixed":"initial",
        bottom: 0,
        backgroundColor: "black"

    }),

    navButton: {
        minWidth: 70
    },
    dots: {
        display: "flex"
    },
    dot: {
        width: 15,
        height: 15,
        borderRadius: "50%",
        backgroundColor: "white",
        margin: 5,
    },
    activeDot: {
        backgroundColor: "#007BFF"
    },

    audioPlayer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //borderRadius: 5,
        width: "calc(100% - 20px)",
        margin: 10,
        minHeight: 200
    },
    audioThumb: {
        width: "100%",
        height: "auto",
        position: "absolute",
        cursor: "pointer"
    },

    playIndicator: {
        bottom: 10,
        right: 10,
        position: "absolute"
    },
    audioButton: {
        fontSize: 80
    },

}));


export default observer(function Members({ parentMatch, tagAsParam, navigation="sticky"
                                                , isStarfish=false
                                             , showLogoImage=true
                                             , showBottomTitle=false
}) {
    const classes = useStyles({navigation, isStarfish});
    const params = useParams();
    const tag = params && params.tag ? params.tag : tagAsParam;
    const { t } = useTranslation();
    const { onboardStore } = useStores();

    //const { memberCards, memberCardsLoading } = onboardStore;
    const [audioIsPlaying, setAudioIsPlaying] = useState(false);
    const [currentSongURL, setCurrentSongURL] = useState(null);

    useEffect(() => {
        (async () => {
            await onboardStore.loadMemberOnboardingCards();
        })();
    }, []);

    useEffect(() => {
        localStore.setTag(tag);
        stopTrack();
    }, [tag]);


    const pauseTrack = () => {
        if (currentSongURL) {
            currentSongURL.pause();
        }
        setAudioIsPlaying(false);
    };

    const stopTrack = () => {
        if (currentSongURL) {
            currentSongURL.pause();
            setCurrentSongURL(null);
        }
        setAudioIsPlaying(false);
    };


    const playTrack = () => {
        if (currentSongURL) {
            currentSongURL.play();
            setAudioIsPlaying(true);
            return;
        }

        if (!localStore.isAudio) return;
        let audio = new Audio(localStore.currentItem.mediaURL);

        if (!currentSongURL) {
            setCurrentSongURL(audio);
            audio.play();
            setAudioIsPlaying(true);
            return;
        }

        if (currentSongURL.src !== localStore.currentItem.mediaURL) {
            currentSongURL.pause();

            setCurrentSongURL(audio);
            audio.play();
            setAudioIsPlaying(true);
            return;
        }
        setAudioIsPlaying(true);
        audio.play();
    };


    const localStore = useLocalObservable(() => ({
        tag,

        setTag(value) {
            this.tag = value;
        },
        get currentItem() {
            let item = onboardStore.memberCards.find(item => {
                return item.subType === this.tag;
            });
            return item;
        },

        get currentItemActions() {
            if (!this.currentItem) return [];
            let texts = this.currentItem.actionButtonText;
            let urls = this.currentItem.actionButtonURL;
            if (!texts || !urls) return [];
            if (!isArray(texts))
                texts = [texts];
            if (!isArray(urls))
                urls = [urls];
            return zip(texts, urls);
        },

        get isAudio() {
            if (!this.currentItem) return false;
            else return isAudio(this.currentItem);
        },

        get position() {
            return onboardStore.memberCards.findIndex(item => {
                return item.subType === this.tag;
            });
        },

        get isFirst() {
            if (onboardStore.memberCards.length === 0) return true;
            return this.position === 0;
        },

        get isLast() {
            if (onboardStore.memberCards.length === 0) return true;
            return this.position === (onboardStore.memberCards.length - 1);
        },

        get prevLink() {
            if (this.position === 0) return null;
            return joinPath(parentMatch.url, "onboard", onboardStore.memberCards[this.position - 1].subType);
        },

        get nextLink() {
            if (this.position === (onboardStore.memberCards.length - 1)) return null;
            return joinPath(parentMatch.url, "onboard", onboardStore.memberCards[this.position + 1].subType);
        },

    }));


    return (<div className={classes.root}>
        {localStore.currentItem
            &&
            <>
                <div className={classes.content}>
                    {showLogoImage && <div className={classes.banner}>
                        <img src={localStore.currentItem.imageURL} className={classes.bannerImg} />
                    </div>}
                    <Typography variant={"h6"} align={"center"}
                        className={classes.description}>{localStore.currentItem.description}</Typography>
                    {(localStore.currentItem.mediaURL && !localStore.isAudio)
                        && <iframe className={classes.videoPlayer}
                            src={`https://player.vimeo.com/video/${localStore.currentItem.mediaURL}`}></iframe>}

                    {(localStore.isAudio)
                        &&
                        <div className={classes.audioPlayer}>
                            <img className={classes.audioThumb} src={localStore.currentItem.thumbnailURL} onClick={audioIsPlaying ? pauseTrack : playTrack} />
                            {false && <>{audioIsPlaying
                                ? <IconButton className={classes.audioButton} onClick={pauseTrack}>
                                    <PauseIcon fontSize="inherit" /></IconButton>
                                : <IconButton className={classes.audioButton} onClick={playTrack}>
                                    <PlayIcon fontSize="inherit" /></IconButton>}</>}
                            {audioIsPlaying && <img
                                alt="play indicator"
                                className={classes.playIndicator}
                                src="https://m.media-amazon.com/images/G/01/digital/music/player/web/dragonfly/eqSmBlueLoop.gif">
                            </img>}
                        </div>
                    }
                    <Box display="flex" flexDirection="column" style={{ gap: "30px 20px" }}>
                        {localStore.currentItemActions.map(x => {
                            return <Button key={x[0]} variant={"contained"} color={"primary"} component={"a"} href={x[1]} target={'_blank'}>{x[0]}</Button>;
                        })}
                    </Box>
                </div>
                <Container maxWidth={"sm"} className={classes.container} disableGutters className={classes.navigation}>
                    {(localStore.isFirst) ? <span className={classes.navButton} /> : <Button className={"navButton"} variant={"text"} href={localStore.prevLink}>{t("prev")}</Button>}
                    <div className={classes.dots}>
                        {onboardStore.memberCards.map((x, i) => {
                            return <Link to={joinPath(parentMatch.url, "onboard", onboardStore.memberCards[i].subType)} key={i} className={clsx(classes.dot, { [classes.activeDot]: i === localStore.position })}></Link>;
                        })}
                    </div>
                    {(localStore.isLast) ? <span className={classes.navButton} /> : <Button className={"navButton"} variant={"text"} href={localStore.nextLink}>{t("next")}</Button>}
                </Container>
                {showBottomTitle && <Typography variant={"h4"} align={"center"}>{localStore.currentItem.title}</Typography>}
            </>}
    </div>);

});