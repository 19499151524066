import React, { useEffect, useMemo} from 'react';
import ProductDetailDescription from './ProductDetailDescription';
import NoProductFound from '../NoProductFound';
import {useHistory, useParams} from "react-router";
import {useLocalObservable} from "mobx-react-lite";
import {useStores} from "../../../hooks/use-stores";
import LoadingIndicator from "../../../components/shop/LoadingIndicator";
import {observer} from "mobx-react";
import Container from "@material-ui/core/Container";
import {joinPath} from "../../../utils/helpers";
import {useShopType} from "../useShopType";

function ProductDetail ({shoppingMatch, shoppingRoot, categoryId, subCategoryId, productSKU}) {

  const shopOptions = useShopType()
  const history = useHistory()
  const params = useParams()
  const {authStore, shopStore, commonStore} = useStores()
  const {isGuest, isQuickBuy } = authStore
  const {language} = commonStore
  const {subscriptionInfoLoading, parentCategoryIds, productDetail, productDetailError, productDetailLoading, productsByCategory, customerLevelID, market} = shopStore





  useEffect( ()=> {
    window.scrollTo(0, 0)
  },[])

  useEffect( ()=> {
    shopStore.loadProductDetail({categoryId,subCategoryId, language, productSKU, customerLevelID, marketID:market, shopType: shopOptions.shopId })
  },[productSKU, subscriptionInfoLoading])


  const localStore = useLocalObservable(() => ({
    categoryId: categoryId,

    get parentCategory() {
      if (!parentCategoryIds) return null;
      return parentCategoryIds.find((category) => {
        return category.parentCategoryID === this.categoryId
      })
    },
    get childCategories(){
      return this.parentCategory && this.parentCategory.childCategories
    },
    get categories(){
      if (productsByCategory && this.childCategories) {
        return this.childCategories.filter((childCategory) => {
          const id = productsByCategory.filter((item) => {
            return item.productCategoryID === childCategory.childCategoryID
          })
          if (!id.length < 1) {
            return childCategory
          }
          return ''
        })
      } else
        return []
    }
  }))


  const onAdditionalProductHandler = async (product) => {
    if ( subCategoryId ){
      await history.push(joinPath(shoppingMatch.url, `/category/${categoryId}/sub-category/${subCategoryId}/product-detail/${product.productSKU}/`))
    }
    else
      await history.push(joinPath(shoppingMatch.url, `/category/${categoryId}/product-detail/${product.productSKU}/`) )
  }

  if ( shopOptions.shopId==="subscriptions" &&  subscriptionInfoLoading === null ) return null;

    return (
      <>
        <Container maxWidth={"md"} className="product-description">
          {(productDetail && !productDetailError)
              ? <>
                {
                  productDetailLoading ?
                      (<LoadingIndicator />) :
                      (<ProductDetailDescription
                          shoppingMatch={shoppingMatch}
                          shoppingRoot={shoppingRoot}
                          parentCategory={localStore.parentCategory}
                           onAdditionalProductHandler={(product) => onAdditionalProductHandler(product)}
                          product={productDetail}
                          isQuickBuy={isQuickBuy}
                          isGuest={isGuest}
                          categoryId={categoryId}
                      />)
                }

              </>
              : <>
                <NoProductFound error={productDetailError} />
                </>}
        </Container>
      </>
    )

}

export default observer(ProductDetail);
