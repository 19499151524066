import {joinPath} from "./helpers";
import each from "lodash/each";

export const isAudio = (item) => {

    return (((item.mediaType === "audio" || item.productType === "audio") && !item.isPack)
        || (item.mediaType === undefined
            && item.mediaURL && item.mediaURL.endsWith("mp3")))
        && (!item.files || item.files.length === 0);

};


export const isVideo = (item) => {
    return (((item.mediaType === "video" || item.productType === "video") && !item.isPack)
        || (item.mediaType === undefined && item.mediaURL && !item.mediaURL.endsWith("mp3")
            && !item.mediaURL.includes("epub")))
        && (!item.files || item.files.length === 0);
};

export const isVimeo = (item) => {
    return  item && item.url && item.url.indexOf("player.vimeo.com")>-1
}

export const isVimeoAlbum = (item) => {
    return  item && item.videos&& item.videos.length>1
}

export const isBook = (item) => {

    return ((item.mediaType === "book" || item.productType === "book")
        || (item.mediaType === undefined
            && item.mediaURL && item.mediaURL.includes("epub")))
        && (!item.files || item.files.length === 0);

};

export const isPack = (item) => {
    return (item.files && item.files.length > 0) || (!!item.isPack);
};

export const packUrl = (parentMatch, item, hasLTPremium, hasSuperAppPlus, isStarfish ) => {
    let prefix ="";
    if ( hasSuperAppPlus && isStarfish  ){
        prefix = "/royalstarfish"
    } else if ( isStarfish  ){
        prefix = "/starfish"
    }

    if ( item.seriesValue==="LT" &&  each( item.files, x=> !x.mediaURL ) ){
        if ( !hasLTPremium )
            return   joinPath( prefix, "/members/marketing_systems" )
    }

    return (parentMatch ? joinPath(parentMatch.url, `packs`, item.mediaID) : `packs/${item.mediaID}`)
};

export const isTypeObservable = (item) => {
    return isVimeoAlbum(item)|| isVimeo(item) || isAudio(item) || isVideo(item) || isBook(item) || isPack(item);
};

export const isFullDetails = (item) => {
    return  item.hasPurchased !== undefined
};