import React from "react";
import {Route} from "react-router";
import GoGetter from "./GoGetter";
import StarGetter from "./StarGetter";
import PowerPlayer from "./PowerPlayer";
import Core4 from "./Core4";
import MarketingSystem from "./MarketingSystem";
import TopGun from "./TopGun";
import {joinPath} from "../../utils/helpers";
import IFrame from "../../components/IFrame";
import CompensationPlan from "./CompensationPlan";
import Policies from "./Policies";
import Compliance from "./Compliance";

function MembersRoutes( parentMatch, loginUID ) {

    return[
        <Route
            path={joinPath(parentMatch.url, "/members/sot")}
            render={() =><IFrame url={`https://checkout.lifeinfoapp.com/quickcheckout.aspx?type=subnew&id=754&login=${loginUID}`} />
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/go-getter")}
            render={() => <GoGetter/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/compensation-plan")}
            render={() => <CompensationPlan/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/userprofile/profile/policies")}
            render={() => <Policies/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/star-getter")}
            render={() => <StarGetter/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/power_player")}
            render={() => <PowerPlayer/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/programs and explanations/power player")}
            render={() => <PowerPlayer/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/core4")}
            render={() => <Core4 parentMatch={parentMatch}/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/programandexplanation/core 4")}
            render={() => <Core4 parentMatch={parentMatch}/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/marketing_systems")}
            render={() => <MarketingSystem/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/top-gun")}
            render={() => <TopGun/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/compliance/test")}
            render={() => <IFrame url={"https://legacy.lifeinfoapp.com/ComplianceTest.aspx?ssl=1"}/>
            }
        />,
        <Route
            path={joinPath(parentMatch.url, "/members/compliance")}
            render={() => <Compliance/>
            }
        />,
        <Route
        path={joinPath(parentMatch.url, "/members/compensation_plan")}
        render={() => <CompensationPlan/>
        }
        />,]

}

export default MembersRoutes;