import React, {useMemo} from 'react';
import { useHistory } from "react-router"
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useStores } from "../../../hooks/use-stores";
import { useTranslation } from 'react-i18next';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
import {joinPath} from "../../../utils/helpers";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        color: 'black',
        height: '100%',
        minHeight: '100%'
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        textAlign: 'center',
        marginTop: 0,
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    createNewButton: {
        backgroundColor: '#0095f6',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(0, 164, 227, .7)'
        }
    },
    divider: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    select: {
        fontSize: '1rem',
        marginBottom: theme.spacing(2)
    },
    chooseFile: {
    },
    lengthWarning: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: 'orange'
    }
}));

const ChoosePath = ({ lifelineMatch }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { authStore, commonStore, lifelineStore } = useStores();
    const [transcoding, setTranscoding] = React.useState(null);


    // iOS compresses videos by default.
    // Use multiple in the input disables compression for ios - a hack to avoid double compressing
    // See https://stackoverflow.com/questions/63631803/mobile-safari-ios-input-type-file-does-no-longer-convert-downscale-vide
    // We enforce a single file limit so only one file will can be selected. Thanks Apple.
    const isIos = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)


    const videoDurationLimit = lifelineStore.videoDuration(authStore.currentUser.userID);

    console.log("isIos", isIos )
    const ffmpeg = useMemo(() => createFFmpeg({
        ...( true
            ? {   //use own built version of ffmpeg-core for ios
                corePath: window.location.origin + process.env.PUBLIC_URL + "/ffmpeg/ffmpeg-core.js",
              }
            :{}),
        log: true }), []);


    ffmpeg.setProgress(({ ratio }) => {
        setTranscoding("Transcoding progress: "  + (ratio * 100).toFixed(2) )
    });

    const doTranscode = async (file) => {
        try {
            setTranscoding("Preparing for transcoding")
            await ffmpeg.load();
            setTranscoding("Loading file")
            ffmpeg.FS('writeFile', file.name, await fetchFile(file));
            await ffmpeg.run('-i', file.name, '-s', '854x480', '-preset', 'fast', 'test.mp4');
            setTranscoding("Loading transcoded file")
            const data = ffmpeg.FS('readFile', 'test.mp4');
            setTranscoding(null)
            return new Blob([data.buffer], {type: 'video/mp4'})
        }catch (e) {
            console.error("error happens: " + e.message )
            console.error(e.stack)
            setTranscoding( `${ e.message }\n${e.stack}` )
            debugger
        }
    };

    const onFileSelected = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        if (files.length !== 1) {
            commonStore.warn('Please select a single file');
        } else {
            validateVideo(e.target.files[0]);
        }
    }

    const videoIsValid =  async (file) => {
        const blob = await doTranscode(file);
        if ( !blob )  return;
        // var blob = new Blob(
        //     [file],x
        //     { "type": `${file.type}` }
        // );

        lifelineStore.videoFile = blob;
        history.push(joinPath( lifelineMatch.url, `reviewVideo`));
    }

    const validateVideo = (file) => {
        let video = document.createElement('video');

        video.onloadedmetadata = () => {
            if (video.duration > videoDurationLimit) {
                commonStore.warn('Video duration is too long. Please choose a shorter video');
            } else {
                videoIsValid(file);
            }
            window.URL.revokeObjectURL(video.src);
        }
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);
    }


    return (
        <>
            <div style={{ color: 'black', textAlign: 'left', background: 'rgb(222, 239, 255)', borderRadius: '10px', padding: '10px' }}>
                <Typography style={{ 'marginBottom': '8px' }}>{t('lifeline:createVideo.choosePath.instructions')}</Typography>
                <Typography style={{ 'marginBottom': '8px' }}>{t('lifeline:createVideo.choosePath.instructions1')}</Typography>
                <Typography>{t('lifeline:createVideo.choosePath.instructions2')}</Typography>
            </div>
            <Container className={classes.wrapper}>
                <div className={classes.content}>
                    <Grid container xs={12} alignItems="center" justifyContent="center" direction="column">
                        <div>
                            <Typography className={classes.select} variant="body1">{t('lifeline:createVideo.choosePath.selectExisting')}</Typography>
                            {transcoding? <pre>{transcoding}</pre> :
                                <form className={classes.chooseFile}>
                                <Button className={classes.createNewButton} variant="contained" component="label">
                                    {t('lifeline:createVideo.choosePath.selectVideo')}
                                    <input hidden type="file" accept="video/mp4,video/*" onChange={onFileSelected} />
                                </Button>
                            </form>}
                            <Typography className={classes.lengthWarning} variant="body1">{t(`lifeline:createVideo.choosePath.maxLength${lifelineStore.isSpecialUser(authStore.currentUser.userID) ? 'Special' : ''}`)}</Typography>
                        </div>
                    </Grid>
                </div>
            </Container>
        </>
    )
}

export default ChoosePath;

