import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { useStores } from "../../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import OpenInNew from "@material-ui/icons/OpenInNew";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import { joinPath } from "../../utils/helpers";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import SectionWithSmallIcons from "../../components/Members/SectionWithSmallIcons";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Paper from "@material-ui/core/Paper";
import ThumbMediaList from "../../components/ThumbMediaList";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PageTitle from "../../components/PageTitle";


const useStyles = makeStyles((theme) => ({
    mainContent: {
        color: "white",
        backgroundColor: "black",
    },
    root: {
        flexGrow: 1,
        color: "white"
    },
    whiteColor: {
        color: "white"
    },
    grow: {
        flexGrow: 1
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        color: "white",
        height: "100%",
        "&:hover": {
            color: "white",
            textDecoration: "none",
        }
    },
    okIcon: {
        color: "#35cd3a"
    },
    notOkIcon: {
        color: "#f3545d"
    },

    semicircle: {
        height: "100%"
    },

    score: {
        height: "100%"
    },
    sectionHeader: {
        marginTop: 20
    },
    verticalBarWithLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    verticalBar: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        width: 40,
        height: 150,
        border: "1px solid white",
        borderRadius: 4,
        overflow: "hidden",
        marginBottom: theme.spacing(2)
    },
    verticalBarRequirement: {
        zIndex: 5,
    },
    verticalBarValue: {
        zIndex: 5,
    },
    verticalBarValueFill: {
        zIndex: 1,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#5cb05d"
    },
    lifeLink: {
        color: "#F50557 !important"
    }


}));


export default observer(function Members({ parentMatch }) {
    const classes = useStyles();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const { audioStore, statStore, menuStore, authStore, salesAchieverAudiosStore } = useStores();
    const { series: salesAchieverSeries } = salesAchieverAudiosStore;
    useEffect(() => {
        (async () => {
            await salesAchieverAudiosStore.loadSeries();
        })();
    }, []);


    const { error, statistics } = statStore;
    const { currentUser, subscriptions } = authStore;
    const { autoshipPVCurrentMonth, salesAchieverPVNeeded, salesAchieverCurrentMonth, salesAchieverPriorMonth, salesAchieverCustomers } = currentUser;
    const { exploreLifeItem } = menuStore.membersMenu;
    const [month, setMonth] = useState(moment().format("MMMM").toLowerCase());

    const isPro = salesAchieverPVNeeded === 300;

    useEffect(() => {
        (async () => {

            // await statStore.loadStats()
        })();
    }, []);

    const handleAudioPlay = (media) => {
        audioStore.setMedia(media);
    };
  const handleAudioPause = (media) => {
        audioStore.pauseMedia(media);
    };


    let gaugeValue = (moment().format("MMMM").toLowerCase() === month)
        ? salesAchieverCurrentMonth
        : salesAchieverPriorMonth;


    return (<div className={classes.root}>
        <Grid item container  >
            <Grid item xs={12} sm={6}><PageTitle>{isPro ? "Sales Achiever PRO" : "Sales Achiever"}</PageTitle></Grid>
            <Grid item container justify={"flex-end"} xs={12} sm={6}>
                <Button component={Link} size={"small"}
                    variant={"outlined"}
                    color={"secondary"}
                    to={joinPath(parentMatch.url, "")}>Show Full Office View</Button>
            </Grid>

        </Grid>

        <Select variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={month}
            onChange={e => { setMonth(e.target.value); }}
        >
            <MenuItem value={moment().format("MMMM").toLowerCase()}>{moment().format("MMMM")}</MenuItem>
            <MenuItem value={moment().add(-1, "month").format("MMMM").toLowerCase()}>{moment().add(-1, "month").format("MMMM")}</MenuItem>
        </Select>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {!statistics ? <CircularProgress />
            : <Grid container justify={"space-between"} >

                <Grid item xs={12} container justify={"center"} style={{ height: 200 }} >
                    {isPro
                        ? <div style={{ height: '100%', overflow: 'hidden' }}>
                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                <CircularProgressbar
                                    circleRatio={0.5}
                                    value={100 * gaugeValue / salesAchieverPVNeeded}
                                    strokeWidth={8}
                                    styles={buildStyles({
                                        rotation: 0.75,
                                        strokeLinecap: 'round',
                                        textSize: '20px',
                                        pathTransitionDuration: 1,
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        textColor: 'white',
                                        trailColor: '#EBEEF1',
                                        backgroundColor: '#F2C94C',
                                    })}
                                />

                                <div style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: '0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >
                                    <div className="text medium">
                                        <div className={classes.score}><Typography variant={"h4"} align="center">{gaugeValue} PV</Typography></div>
                                    </div>
                                    <Link className={classes.lifeLink} to={joinPath(parentMatch.url, "salesachiever/details")}>Sales Achiever PRO ⓘ <OpenInNew /></Link>


                                </div>
                                <div style={{
                                    position: 'absolute',
                                    width: '100%',
                                    display: 'flex',
                                    bottom: 0,
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                                >
                                    <Typography variant={"h6"} align="center">0</Typography>
                                    <Typography variant={"h6"} align="center">300</Typography>

                                </div>
                            </div>
                        </div>
                        : <Grid item xs={12} md={8} lg={6} justify={"space-between"} container >
                            <div className={classes.verticalBarWithLabel} >
                                <div className={classes.verticalBar} >
                                    <span className={classes.verticalBarRequirement}>{salesAchieverPVNeeded}</span>
                                    <span className={classes.verticalBarValue}>{gaugeValue}</span>
                                    <div className={classes.verticalBarValueFill} style={{ height: `${((gaugeValue / salesAchieverPVNeeded) * 100)}%` }} />
                                </div>
                                <div>
                                    {gaugeValue} PV
                                            </div>
                            </div>
                            <span>OR</span>
                            <div className={classes.verticalBarWithLabel} >
                                <div className={classes.verticalBar} >
                                    <span className={classes.verticalBarRequirement}>10</span>
                                    <span className={classes.verticalBarValue}>{Math.min(salesAchieverCustomers, 10)}</span>

                                    <div className={classes.verticalBarValueFill} style={{ height: `${((Math.min(salesAchieverCustomers, 10) / 10) * 100)}%` }} />
                                </div>
                                {salesAchieverCustomers} Super App
                                        </div>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} container justify={"space-between"} >
                    <Typography variant={"h6"} className={classes.sectionHeader} >Core 4</Typography>
                    <Link className={classes.lifeLink} to={joinPath(parentMatch.url, "core_4/manage")}>Manage <OpenInNew /></Link>
                </Grid>

                <Grid item xs={12} container justify={"space-between"} spacing={2} >
                    <Grid item xs={12} sm={6} md={6} lg={3} >
                        <Paper component={Link} className={classes.card} to={joinPath(parentMatch.url, "core_4", "super_app")}>
                            <Typography align="center">Super App</Typography>
                            {subscriptions.hasSuperApp || subscriptions.hasSuperAppPlus
                                ? <CheckCircle className={classes.okIcon} />
                                : <Cancel className={classes.notOkIcon} />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} >
                        <Paper component={Link} className={classes.card} to={joinPath(parentMatch.url, "core_4", "autoship")}>
                            <Typography align="center">Super App</Typography>
                            <Typography align="center">{autoshipPVCurrentMonth} PV</Typography>
                            {(autoshipPVCurrentMonth >= 100)
                                ? <CheckCircle className={classes.okIcon} />
                                : <Cancel className={classes.notOkIcon} />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} >
                        <Paper component={Link} className={classes.card} to={joinPath(parentMatch.url, "core_4", "marketing_system")}>
                            <Typography align="center">Marketing System</Typography>
                            {currentUser.isLTMember
                                ? <CheckCircle className={classes.okIcon} />
                                : <Cancel className={classes.notOkIcon} />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} >
                        <Paper component={Link} className={classes.card} to={joinPath(parentMatch.url, "core_4", "sot")}>
                            <Typography align="center">Standing Order Ticket</Typography>
                            {subscriptions.hasSOT
                                ? <CheckCircle className={classes.okIcon} />
                                : <Cancel className={classes.notOkIcon} />}
                        </Paper>
                    </Grid>
                </Grid>

                {salesAchieverSeries &&
                    <>
                        <Typography variant="h6" className={classes.sectionHeader}>Sales Achiever Audios</Typography>
                        <ThumbMediaList items={salesAchieverSeries}
                            onAudioPlay={handleAudioPlay}
                            onAudioPause={handleAudioPause}
                            forceType={"audio"}
                        />

                    </>}


                <SectionWithSmallIcons def={exploreLifeItem} parentMatch={parentMatch} />

            </Grid>

        }


        {showInfoModal && <Dialog onClose={e => { setShowInfoModal(false); }} open={showInfoModal}>
            <DialogContent>
                Sales Achiever Pro INFO
                    </DialogContent>
        </Dialog>}

    </div>);

});