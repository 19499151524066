
import React, {useEffect} from 'react';
import ReactAudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Avatar, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import Stop from "@material-ui/icons/HighlightOffOutlined";
import Forward15 from "../icons/Forward15";
import Rewind15 from "../icons/Rewind15";
import Play from "../icons/Play";
import Pause from "../icons/Pause";
import '../css/audioplayer.scss';
import { useStores } from '../hooks/use-stores';
import { observer } from "mobx-react-lite";
import clsx from 'clsx';
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    playerContainer: {
        // position: "absolute",
        //  top: "-58px",
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        height: "56px",
        zIndex: 2000,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: "flex",
        justifyContent: "center"
    },
    starfishBackground: {
        backgroundColor: '#3579f6!important',
        '& .rhap_main-controls': {
            backgroundColor: '#3579f6!important'
        }
    },
    phoneStop: {
        position: "absolute",
        right: 5,
        top: 25,
        width: '30px',
        height: '30px'
    },
    moveUp: {
        '& .rhap_container': {
            bottom: '56px',
            '@media all and (display-mode: standalone)': {
                bottom: '80px',
            }
        }
    },
    audioTitle: {
        whiteSpace: "nowrap",
        color: "white",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: 14,
        [theme.breakpoints.up(701)]: {
            maxWidth: "200px",
        },
        [theme.breakpoints.up(1001)]: {
            maxWidth: "300px",
        }
    },
    audioSpeaker: {
        whiteSpace: "nowrap",
        color: "white",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: 12,
        [theme.breakpoints.up(701)]: {
            width: "200px",
        },
        [theme.breakpoints.up(1001)]: {
            width: "300px",
        }
    },
    audioPlayer: {
        backgroundColor: "#1d1d1d",
        maxWidth: 1132,
        [theme.breakpoints.down(700)]: {
            height: 120,
            backgroundColor: "rgb(71,119,238)"
        }

    },
    audioTrackInfo: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 0,
        [theme.breakpoints.down(700)]: {
            marginTop: 25,
            //marginBottom: 5,
        }
    },
    largePict: {
        width: 40,
        height: 40
    },
    smallPict: {
        width: 32,
        height: 32
    }
}));

const AudioPlayer = ({ isPhone, isStarfish }) => {
    const classes = useStyles();

    const { audioStore } = useStores();
    const { mediaURL, mediaTitle, mediaSpeakers, mediaImageURL } = audioStore;

    useEffect(()=>{
        audioStore.restartIfNeed()
        return ()=>{
            audioStore.pauseMedia()
        }
    },[])

    if (!mediaURL ) {
        return null;
    }

    return (
        <div className={clsx(classes.playerContainer,
            isStarfish && classes.moveUp
        )}>
            <ReactAudioPlayer autoPlay
                showJumpControls={true}
                progressJumpSteps={{ backward: 15000, forward: 15000 }}
                src={mediaURL}
                loop={false}
                className={
                    clsx(classes.audioPlayer,
                        isStarfish && classes.starfishBackground
                    )}
                onPause={e => { audioStore.setAudioPaused(true); }}
                onPlay={e => { audioStore.setAudioPaused(false); }}
                onListen={e => { audioStore.setAudioProgress(e); }}
                onLoadedData={e => { audioStore.setStartPosition() }}


                layout="stacked"
                customAdditionalControls={[]}
                customProgressBarSection={[RHAP_UI.CURRENT_TIME, RHAP_UI.DURATION]}
                showDownloadProgress={false}
                customControlsSection={[
                    <Stop className={clsx(isPhone && classes.phoneStop)} onClick={e => { audioStore.stopAudio(); }} />,

                    <div className={classes.audioTrackInfo}>
                        {mediaImageURL && <ListItemAvatar className={classes.listItem}>
                            <Avatar variant="rounded"
                                className={classes.largePict}
                                alt={` `}
                                src={`${mediaImageURL}`}
                            />
                        </ListItemAvatar>}
                        <ListItemText classes={{ primary: classes.audioTitle, secondary: classes.audioSpeaker }}
                            primary={mediaTitle} secondary={mediaSpeakers}

                        />
                    </div>,
                    RHAP_UI.PROGRESS_BAR,
                    RHAP_UI.MAIN_CONTROLS
                ]}
                customIcons={{
                    rewind: <Rewind15 style={{ fontSize: "40px" }} />,
                    forward: <Forward15 style={{ fontSize: "40px" }} />,
                    play: <Play style={{ fontSize: "38px" }} />,
                    pause: <Pause style={{ fontSize: "38px" }} />,
                }}
                ref={(element) => { audioStore.setElement(element); }} />
        </div>
    );
};

export default observer(AudioPlayer);