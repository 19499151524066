import React, {useState} from "react";
import {Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {starFishColor} from "../../components/styles";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
    pointsList:{
        alignItems:"flex-start",
        justifyContent:"flex-start",
        flexDirection:"column",
        display:"flex"
    },
    subsection: {
        paddingTop: 16,
        paddingBottom: 16,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
    },

    pointText:{
        paddingLeft:56,
        paddingRight:96,
        paddingTop:14,
        paddingBottom:14,
        width:"100%"
    },
    subPoints:{
        marginTop: 14,
        borderTop: "1px solid rgba(128, 128, 128, 0.5)",
        padding: "0 8px"
    },
    subPointsUL:{
        listStyleType: "square",
        margin: 0,
        marginTop: 14,
        padding: 0,
        display:"none"
    },
    expanded:{
        display:"block"
    }

}));


function TopGun(){

    const classes = useStyles();
    const {commonStore, videoStore} = useStores();
    const {t} = useTranslation("starfish");
    const [point2Expanded, setPoint2Expanded] = useState(false)
    const [point3Expanded, setPoint3Expanded] = useState(false)

    const showVideo  = ()=>{
        videoStore.setMedia( {url: "https://vimeo.com/780782440/ef1ee5f971",  title:"The Top Gun Contest", vimeo: true } )
    }

    return <div>

        <section className={clsx( classes.section)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/topgun_bg.jpg`}
                className={classes.middleImage} role="img"/>
            <img
                src={commonStore.language === "fr"
                    ? `${process.env.PUBLIC_URL}/img/topgun_fr.png`
                    : `${process.env.PUBLIC_URL}/img/topgun_en.png` }
                className={classes.overlayImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("topgun.who_will")}<span className={classes.linkColor}>{t("topgun.win")}</span></Typography>
                <Typography align="center" className={classes.mainText}  variant="body1">{t("topgun.thousands_will_compete")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <Button color="primary" variant="contained" onClick={showVideo} atarget="_blank" ahref={ "https://vimeo.com/780782440/ef1ee5f971"}>
                {t("topgun.watch_video")}</Button>
        </section>
        <section className={clsx( classes.section)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/topgun_cup.jpeg`}
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section, classes.inverse)}>
            <div className={clsx( classes.textContainer)}>
                <section className={clsx( classes.subsection)}>
                    <Typography align="center" className={classes.headText} variant="h3">{t("topgun.why_top_gun")}</Typography>
                </section>
                <section className={clsx( classes.subsection)}>
                    <Typography align="center" className={classes.mainText}  variant="body1">{t("topgun.topgun_goals")}</Typography>
                </section>
                <section className={clsx( classes.subsection)}>
                    <Typography align="center" className={classes.mainText}  variant="body1">{t("topgun.find_topgun_rules")}</Typography>
                </section>
            </div>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3"><span className={classes.linkColor}>{t("topgun.top_gun")}</span>{t("topgun.contest_rules")}</Typography>
                <Typography style={{fontStyle:"italic"}}  align="center" className={classes.mainText}  variant="body1">{t("topgun.scores_are_based")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section, classes.pointsList)}>
            <Typography  className={clsx( classes.pointText, classes.condensedText)}   variant="body1">{t("topgun.point1")}</Typography>
        </section>
        <section className={clsx( classes.section, classes.pointsList)}>
            <div className={classes.pointText} >
                <Typography variant="body1" className={classes.condensedText}
                        onClick={ () => setPoint2Expanded(!point2Expanded)}>{t("topgun.point2")}</Typography>
                <div className={clsx( classes.subPoints) }>
                    <ul className={clsx( classes.subPointsUL, {[classes.expanded]: point2Expanded})}>
                        <li style={{ marginLeft: "15pt", fontStyle:"italic"}}>{t("topgun.two_teams_below_leader")}</li>
                        <li style={{marginTop:6, marginLeft: "15pt", fontStyle:"italic"}}>{t("topgun.two_teams_leader")}</li>
                        <li style={{marginTop:6, marginLeft: "15pt", fontStyle:"italic"}}>{t("topgun.three_teams")}</li>
                        <li style={{marginTop:6, marginLeft: "15pt", fontStyle:"italic"}}>{t("topgun.four_teams")}</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className={clsx( classes.section, classes.pointsList)}>
            <div className={classes.pointText} >
                <Typography variant="body1" className={classes.condensedText}
                        onClick={ () => setPoint3Expanded(!point3Expanded)}>{t("topgun.point3")}</Typography>
                <div className={clsx( classes.subPoints) }>
                    <ul className={clsx( classes.subPointsUL, {[classes.expanded]: point3Expanded})}>
                        <li style={{ marginLeft: "15pt", fontStyle:"italic"}}>{t("topgun.more_than_4")}</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className={clsx( classes.section, classes.pointsList)}>
            <Typography className={ clsx( classes.pointText, classes.condensedText)}  variant="body1">{t("topgun.point4")}</Typography>
        </section>
        <section className={clsx( classes.section, classes.pointsList)}>
            <Typography className={clsx( classes.pointText, classes.condensedText)}  variant="body1">{t("topgun.point5")}</Typography>
        </section>


        <section className={clsx( classes.section)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/topgun_winners.jpeg`}
                className={classes.middleImage} role="img"/>
        </section>

        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">
                    <span className={classes.linkColor}>{t("topgun.winners_title_prefix")}</span>
                    {t("topgun.winner_title_middle")}
                    <span className={classes.linkColor}>{t("topgun.winner_title_suffix")}</span></Typography>
             </div>
        </section>

        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
               <Grid container spacing={3} alignItems={"center"} justifyContent={"center"}>
                    <Grid item sm={3} xs={12}>
                        <div style={{padding: 12, textAlign:"center"}}>
                            <strong>Below-Leader:</strong>
                        </div>
                        <div style={{padding: 12, textAlign:"center"}}>
                            Jason Schwartz
                        </div>
                    </Grid>
                   <Grid item sm={3} xs={12} >
                        <div style={{padding: 12, textAlign:"center"}}>
                            <strong>Leader:</strong>
                        </div>
                        <div style={{padding: 12, textAlign:"center"}}>
                            Peter Mbanza
                        </div>
                    </Grid>
                   <Grid item sm={3} xs={12}>
                        <div style={{padding: 12, textAlign:"center"}}>
                            <strong>3 Team:</strong>
                        </div>
                        <div style={{padding: 12, textAlign:"center"}}>
                            Doug & Tiffany Stroh
                        </div>
                    </Grid>
                   <Grid item sm={3} xs={12}>
                        <div style={{padding: 12, textAlign:"center"}}>
                            <strong>4 Team:</strong>
                        </div>
                        <div style={{padding: 12, textAlign:"center"}}>
                            Wayne & Raylene MacNamara
                        </div>
                    </Grid>

               </Grid>
             </div>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>

    </div>
}

export default TopGun