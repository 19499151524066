import React, { useEffect } from 'react';
import {
    Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import services from '../services';
import { useStores } from '../hooks/use-stores';
import ShareLinks from '../components/ShareLinks';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
}));

const ShareEnrollment = () => {
    const { authStore, } = useStores();
    const classes = useStyles();
    const { currentUser } = authStore;
    const { isSuperApp, isSuperAppPlus, isStarfish } = currentUser;

    const [links, setLinks] = React.useState(null);

    useEffect(() => {
        if (["HT", "AU", "BS"].includes(currentUser?.country)) {
            setLinks([...platformLinks, ...starfishLinks]);
        } else {
            setLinks(starfishLinks);
        }
    }, []);

    const generateLink = async (selection) => {
        const selectionValue = selection.value;
        const fromStarfish = selectionValue && selectionValue.includes('starfish') ? true : false;

        return (selectionValue === "life")
            ? { url: "https://my.lifeinfoapp.com/starfish/life" }
            : await services.Enrollment.generateEnrollmentLink(selectionValue, 'en', fromStarfish);
    }

    const hasAccess = React.useMemo(() => {
        if (!!isStarfish || !!isSuperApp || !!isSuperAppPlus) {
            return true;
        } else {
            return false;
        }
    }, [isStarfish, isSuperApp, isSuperAppPlus]);

    const platformLinks = [
        {
            value: 'superapp', text: 'Platform Enrollment',
        },
        {
            value: 'superappplus', text: 'Platform + Enrollment'
        }
    ];

    const starfishLinks = [
        {
            value: 'topcustomer-starfish', text: 'Top Customer (Starfish + Life Accelerator)'
        },
        {
            value: 'starfish', text: 'Starfish'
        },
        {
            value: 'life', text: 'Life Accelerator (For existing Starfish customers)'
        }
    ];

    return (
        <Container maxWidth={"md"} className={classes.root}>
            <ShareLinks
                links={links}
                onGenerateLink={generateLink}
                header={!!currentUser.isMember ? "Enrollment Links" : "Invite Friends"}
                description={!!currentUser.isMember ? "A Customer Enrollment Link is a unique multi-use link that is active for 48 hours. After the 48 hours, it expires and a new link has to be created. " : ""}
                hasAccess={hasAccess}
            />
        </Container>
    );
};

export default ShareEnrollment;