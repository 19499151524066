import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { MenuItem, WalletBalance } from './MenuItem';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '60px'
    },
    gridItem: {
        '&:last-child': {
            paddingRight: 0,
        },
    },
    walletBalance: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        padding: theme.spacing(2),
        minHeight: '100%',
    },
    walletHeader: {
        fontWeight: 'bold',
        fontSize: '.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        }
    },
    menuItem: {
        cursor: 'pointer',
        marginTop: theme.spacing(2),
        borderRadius: 20,
        border: "1px solid #007BFF",
        display: "flex",
        padding: "15px 0 15px 15px",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: 105,
        color: "white",
        flexGrow: 1,
        textDecoration: "none",
        "&:hover": {
            color: "white",
            textDecoration: "none",
        }
    },
    redBorder: {
        border: '1px solid #007BFF',
        borderRadius: '20px'
    },
    menuTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold'
    },
    menuSubtitle: {
        color: 'rgb(109,109,109)'
    }
}));

const Wallet = ({
    availableBalance,
    lifetimeBalance,
    onGoToGiftCards,
    onGoToTransactions,
    onAddMoney,
    onManagePayments,
    onLearnAboutWallet,
    additionalMenuItems,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (<Container className={classes.container}>
        <Grid xs={12} container spacing={2} justify="center" className={classes.topGrid}>
            <Grid item xs={6} className={classes.gridItem}>
                <WalletBalance headerText={t('starfish:wallet.availableBalance')} subtitleText={`${availableBalance}`} />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
                <WalletBalance headerText={t('starfish:wallet.lifetime')} subtitleText={`${lifetimeBalance}`} />
            </Grid>
        </Grid>
        <MenuItem title={t('starfish:wallet.giftCards')} subtitle={t('starfish:wallet.giftCardsInfo')} onClick={onGoToGiftCards} />
        <MenuItem title={t('starfish:wallet.transactionHistory')} subtitle={t('starfish:wallet.transactionHistoryInfo')} onClick={onGoToTransactions} />
        <MenuItem title={t('starfish:wallet.addMoney')} subtitle={t('starfish:wallet.addMoneyInfo')} onClick={onAddMoney} />
        <MenuItem title={t('starfish:wallet.paymentSources')} subtitle={t('starfish:wallet.paymentSourcesInfo')} onClick={onManagePayments} />
        {
            additionalMenuItems
        }
        <MenuItem title={t('starfish:wallet.learnAboutWallet')} subtitle={t('starfish:wallet.learnAboutWalletInfo')} onClick={onLearnAboutWallet} />
    </Container>
    );
}

export default observer(Wallet);