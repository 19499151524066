import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import { Link } from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {joinPath} from "../../../utils/helpers";
import {lineClamp} from "../../../utils/styles";
import clsx from "clsx";
import {useShopType} from "../useShopType";


const useStyles = makeStyles((theme) => ({
  categoryViewOwl:{
    height:280,
    textAlign:"center",
    [theme.breakpoints.down('sm')]: {
      height:150,
      paddingBottom:70,
    },

  },
  item:{
    paddingBottom:10,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  productTitle: {
    paddingTop: 5,
    color: "#fff",
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    lineHeight: 1
  },
  link:{
    textDecoration:"none"
  },
  lineClamp: {
    ...lineClamp,
    fontSize: 12
  },
}))


const FeaturedProductsCarousel = ({ products, parentMatch }) => {
  const classes = useStyles();
  const shopParams = useShopType()
  if (!products) return null

  const options = {
    items: 4,
    loop: true,
    stagePadding: 25,
    center: false,
    rewind: true,
    autoplay: false,
    margin: 15,
    responsive: {
      0: {
        items: 3,
      },
      350: {
        items: 3,
      },
      750: {
        items: 4,
      },
    },
  };

  return (
    <OwlCarousel options={options}>
      {
        products.map((product, i) => {
          let id = product.parentCategoryID;
          return (
            <div className={classes.categoryViewOwl} key={id + product.productSKU}>
              <Link className={classes.link} to={ joinPath(shopParams.home, `category/${product.parentCategoryID}/sub-category/${product.productCategoryID}/product-detail/${product.productSKU}`)}>
                <div style={{ backgroundImage: `url(${product.productImage})`, height: "85%"}}   className={classes.item}/>
                <Typography variant={"body1"} className={ clsx( classes.productTitle, classes.lineClamp)}>{product.productName}</Typography>
              </Link>
            </div>
          )
        })
      }
    </OwlCarousel>
  )
}

export default FeaturedProductsCarousel;