import React, {useEffect} from 'react';
import ProductListItem from './ProductListItem';
import {observer} from "mobx-react";
import {useHistory, useRouteMatch} from "react-router";
import {joinPath} from "../../../utils/helpers";
import {useShopType} from "../useShopType";

function ProductList ({ products, category, categoryId, sizeMD, sizeXS, shoppingRoot }) {

  const history =  useHistory();
  const match =  useRouteMatch();
  const shopParams = useShopType()
  useEffect( ()=> {
    window.scrollTo(0, 0)
  },[])

  const onProductClick = (item) => {
    let parentCategoryID = categoryId;
    if (!parentCategoryID) {
      parentCategoryID = item.parentCategoryID
    }
    // change routeID if promotional or featured items
    if (parentCategoryID === '793' || parentCategoryID === '792') {
      history.push( joinPath(shopParams.home, `/category/${parentCategoryID}/product-detail/${item.productSKU}/${parentCategoryID}` ))
      return
    }
  }

    if (!products) return ('')

    return products.map((item, index) => {
      return (
        <ProductListItem
            sizeMD={sizeMD} sizeXS={sizeXS}
          parentMatch = {match}
          key={item.productID + item.productType + index}
          category={category}
          onProductClick={() => onProductClick(item)}
          item={item}
            parentCategoryID={categoryId||item.parentCategoryID}
            shoppingRoot={shoppingRoot}
        />
      )
    })

}

export default observer(ProductList)