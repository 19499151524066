import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {starFishColor} from "../../components/styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    marginBottom:{
        marginBottom: theme.spacing(2)
    },

    circle:{
        width: 80,
        height: 80,
        borderRadius: "50%",
        ...starFishColor(theme, "borderColor"),
        ...starFishColor(theme, "backgroundColor"),
        borderStyle: "solid",
        borderWidth: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 30,
        fontWeight: "bold",
        padding: 10,
        marginBottom: 20,
        [theme.breakpoints.down("700")]: {
            fontSize: 20,
            marginBottom: 10,
            width: 50,
            height: 50,
            padding: 5,

        }
    },


    linkWithButton: {
        padding: 10,
        whiteSpace: "nowrap",
        width: "100%",
        justifyContent: "space-around",
    }
    ,

    divider:{
        marginRight:-1,
        backgroundColor: "rgba(255, 255, 255, 0.42)",
        [theme.breakpoints.down("700")]: {
           marginRight:"unset",
           height:"1px",
            width:"80%",
            marginBottom:20,
            marginTop:20
        },
    }

}));


function Compliance(){
    const { audioStore, commonStore } = useStores();
    const {t} = useTranslation("starfish");
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down("700"));
    const classes = useStyles();
    const handleAudio = () => {
        audioStore.setMedia({direct:true
            , mediaID:"Compliance"
            , title:"Compliance Training Audio", mediaURL:"https://main.secure.footprint.net//fms/LIFE/FullComplianceAudio.mp3"})
    }


    return <div>
        <section className={clsx( classes.sectionDense)}>
            <img
                src={ commonStore.language === "fr"
                    ? `${process.env.PUBLIC_URL}/img/compliance-fr.jpg`
                    : `${process.env.PUBLIC_URL}/img/compliance-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <Grid  container spacing={isPhone?0:3} alignItems="center" justifyContent={"center"}>
                <Grid item container sm={4} direction="column" alignItems="center" justifyContent={"center"}>

                    <Box display="flex"  alignItems={"center"} className={classes.circle }>
                      1
                    </Box>
                        <Typography align={"center"} className={clsx( classes.mainText )} variant="subtitle1">{t("compliance_page.listen")}</Typography>

                    <Box display="flex"  alignItems={"center"} justifyContent={"spaceBetween"} className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" onClick={handleAudio} >{t("english")}</Button>
                        <Button variant="contained" color="primary" onClick={handleAudio} >{t("french")}</Button>

                    </Box>
                </Grid>
                <Divider  flexItem  orientation={isPhone?"horizontal":"vertical"} className={classes.divider}/>
                <Grid item container sm={4} direction="column" alignItems="center" justifyContent={"center"}>

                    <Box display="flex"  alignItems={"center"} className={classes.circle }>
                       2
                    </Box>
                        <Typography align={"center"} className={clsx( classes.mainText )} variant="subtitle1">{t("compliance_page.test")}</Typography>

                    <Box display="flex"  alignItems={"center"} justifyContent={"spaceBetween"}  className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" component={Link} to={"compliance/test"} >{t("english")}</Button>
                    </Box>
                </Grid>
                <Divider flexItem  orientation={isPhone?"horizontal":"vertical"}  className={classes.divider} />
                <Grid item container sm={4} direction="column" alignItems="center" justifyContent={"center"}>
                    <Box display="flex"  alignItems={"center"} className={classes.circle }>
                        3
                    </Box>

                    <Typography align={"center"} className={clsx( classes.mainText )} variant="subtitle1">{t("compliance_page.review")}</Typography>
                    <Box display="flex"  alignItems={"center"}  className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href={"http://www.lifeleadership.com/desktopmodules/life_membereducation/membereducation/files/Compliance_Month-End_OverviewV3.pdf"} >{t("english")}</Button>
                        <Button variant="contained" color="primary" download target="_blank" href={"http://www.lifeleadership.com/desktopmodules/life_membereducation/membereducation/files/Compliance_Month_End_OverviewV3_French_.pdf"}>{t("french")}</Button>
                    </Box>
                </Grid>
            </Grid>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default Compliance