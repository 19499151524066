import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppBar, Avatar, Badge, Container, Icon, IconButton, ListItem, ListItemAvatar, ListItemText, makeStyles, Menu, MenuItem, Toolbar } from '@material-ui/core';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShareIcon from '@material-ui/icons/Share';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import AudioPlayer from './AudioPlayer';
import { useStores } from '../hooks/use-stores';
import ShopIcon from "@material-ui/icons/ShoppingCart";
import {useShopType} from "../containers/shop/useShopType";
import {joinPath} from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
    appBarRoot: {
        position: "fixed",
        height: 58,
        backgroundColor: "black",
        borderBottom: "1px solid #007BFF"
    },
    appBarContainer: {
        height: 58
    },
    appbarToolbar: {
        height: "100%"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    appLogo: {
        paddingLeft: theme.spacing(1),
        whiteSpace: "nowrap",
        cursor: "pointer"
    },
    hideAppLogoMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    appLogoImg:({isRoyal})=>({
        height: isRoyal?35:27,
        width: 150,
    }),
    appLogoText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "35px",
    },
    grow: {
        flexGrow: 1,
    },
    appbarIcons: {
        whiteSpace: "nowrap"
    },
    whiteIcon: {
        color: "white"
    },
    smallAvatar: {
        width: 30,
        height: 30
    },
}));

const StarfishHeader = ({ currentUser, isPhone, isRoyal, handleLogout,
                            notificationsCounter, handleBellMenuOpen
                             }) => {
    const { t } = useTranslation();
    const { commonStore } = useStores();
    const classes = useStyles({ isRoyal} );
    const history = useHistory();

    const { shopStore } = useStores();
    const shopOptions = useShopType()
    const {cartItemsProp} = shopOptions
    const  cartItems = shopStore[cartItemsProp]
    const [userMenuanchorEl, setUserMenuAnchorEl] = React.useState(null);

    const isUserMenuOpen = Boolean(userMenuanchorEl);

    const handleUserMenuOpen = (event) => {
        history.push({
            pathname:  isRoyal?'/royalstarfish/userprofile':'/starfish/profile',
            state: { fromModule: 'STARFISH' }
        });
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const goToHome = () => {
        history.push({
            pathname:  isRoyal?'/royalstarfish':'/starfish',
            state: { fromModule: 'STARFISH' }
        });
    };

    const goToShare = () => {
        history.push({
            pathname:  isRoyal?'/royalstarfish/share':'/starfish/share',
            state: { fromModule: 'STARFISH' }
        });
    };

    const goToNews = () => {
        history.push({
            pathname: '/starfish/news',
            state: { fromModule: 'STARFISH' }
        });
    };

    return (<>
        <AppBar color={"secondary"} position="static" classes={{ root: classes.appBarRoot }}>
            <Container maxWidth={"md"} className={classes.appBarContainer}>
                <Toolbar disableGutters variant="dense" className={classes.appbarToolbar}>
                    <span className={classes.appLogo}>
                        <img src={
                            isRoyal
                                ? `${process.env.PUBLIC_URL}/img/RoyalHeader.png`
                                : `${process.env.PUBLIC_URL}/starfish_full.png`} className={classes.appLogoImg} onClick={goToHome} />
                    </span>
                    <div className={`${classes.grow}`} >
                    </div>
                    <AudioPlayer isPhone={isPhone} isStarfish={commonStore.onStarfishOrRoyalStarfish} />
                    <div className={classes.appbarIcons} >
                        {( cartItems && cartItems>0 ) ? <Link to={ joinPath(isRoyal?'/royalstarfish':'/starfish', "/shop/cart")}><Badge badgeContent={cartItems} color="secondary">
                            <ShopIcon className={classes.whiteIcon} />
                        </Badge></Link>: null }
                        <IconButton color="inherit" onClick={goToShare}>
                            <ShareIcon className={classes.whiteIcon} />
                        </IconButton>
                        {isRoyal
                            ?   <IconButton aria-label={`show ${notificationsCounter} new notifications`} color="inherit"
                                               onClick={handleBellMenuOpen}>
                                    <Badge badgeContent={notificationsCounter} color="secondary">
                                        <NotificationsIcon/>
                                    </Badge>
                                </IconButton>
                            :   <IconButton color="inherit" onClick={goToNews}>
                                    <NotificationsIcon />
                                </IconButton>}
                        <IconButton color="inherit" onClick={handleUserMenuOpen}>
                            <AccountCircleRoundedIcon className={classes.smallAvatar} />
                        </IconButton>
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
        <Menu
            anchorEl={userMenuanchorEl}
            id={'starfishMenu'}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isUserMenuOpen}
            onClose={handleUserMenuClose}
        >
            <div className={classes.userInfo}>
                <ListItem>
                    <ListItemAvatar></ListItemAvatar>
                    <ListItemText primary={`${currentUser.firstName} ${currentUser.lastName}`} secondary={<>{currentUser.username}  <br /> {currentUser.userID} </>}></ListItemText>
                </ListItem>
            </div>
            <ListItem button onClick={handleLogout}>
                <ListItemIcon className={classes.icon}><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={t("Logout")} />
            </ListItem>
        </Menu>
    </>);
};

export default StarfishHeader;
