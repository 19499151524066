import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import { useStores } from "../hooks/use-stores";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import LanguageIcon from "@material-ui/icons/Language";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import PrintIcon from "@material-ui/icons/Print";
import DownloadIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { composeInitialProps, useTranslation } from "react-i18next";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import BillingInfoForm from "../components/enrollment/billingInfoForm";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useReactToPrint } from 'react-to-print';
import PdfIcon from "../icons/PdfIcon";
import PersonalFormUS from "../components/memberEnrollment/PersonalFormUS";
import PersonalFormAU from "../components/memberEnrollment/PersonalFormAU";
import PersonalFormCA from "../components/memberEnrollment/PersonalFormCA";
import BillingAddressForm from "../components/enrollment/billingAddressForm";
import CartView from "../components/enrollment/cartView";
import Select from "@material-ui/core/Select";
import PersonalFormMX from "../components/memberEnrollment/PersonalFormMX";
import PersonalFormFR from "../components/memberEnrollment/PersonalFormFR";
import PersonalFormPE from "../components/memberEnrollment/PersonalFormPE";
import PersonalFormEC from "../components/memberEnrollment/PersonalFormEC";
import Signature from "../components/signature";
import FormHelperText from '@material-ui/core/FormHelperText';
import ItemDialog from "../components/enrollment/itemDialog";

const headerHeight = 60;
const navMobileHeight = 50;
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        background: "white",
    },
    logo: {
        width: 50,
        height: 50,
    },
    header: {
        height: headerHeight
    },
    navigationWrapper: {
        background: "#f8fafd",
        boxShadow: "7px 0 10px #aeaeae;",
        zIndex: 1,
        padding: "0 8px",
    },
    navigation: {
        [theme.breakpoints.down('md')]: {
            height: navMobileHeight,
        },
    },
    contentPanel: {
        position: "relative",
        background: "#ebebeb",
        flexGrow: 1,

        height: "100vh",
        overflow: "scroll",
        [theme.breakpoints.down(1180)]: {
            height: `calc( 100vh - ${headerHeight + navMobileHeight}px )`,
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5),
        },
    },
    contentPanelFade: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(200,200,200, 0.4)"
    },
    referralInfo: {
        fontSize: "11px"
    },
    tabPanel: {
        width: "100%"
    },
    captionWithButton: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"

    },

    cartSummary: {
        width: "100%",
        backgroundColor: "#f8f8f8",
        padding: 7
    },

    itemCheckBox: {
        marginLeft: -32,
        minWidth: "auto"
    },
    thumb: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(9),
            height: theme.spacing(9),
        },
    },

    lineClamp2: {
        "-webkit-line-clamp": "2 !important"
    },

    lineClamp: {
        margin: "5px 0",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        lineClamp: 2,
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.3em",
        fontSize: 12,
        textAlign: "center"
    },

    additionalSubscriptions: {
        display: "flex",
        flexWrap: "wrap",
        padding: theme.spacing(1)
    },
    additionalSubscription: {
        // marginRight: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        padding: "20px 10px",
        paddingBottom: 5,
        background: "white",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        cursor: "pointer",
        height: "100%"
    },
    termsTitle: {
        padding: 20,
        borderLeft: "5px solid #007BFF",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },

    termsTitleGreen: {
        borderLeft: "5px solid #32c751",
    },

    termsTitleYellow: {
        borderLeft: "5px solid #ff9800",
    },

    temsAndConditions: {
        border: "1px solid #c7c7c7 ",
        padding: 20,
        maxHeight: "60vh",
        overflow: "scroll"
    },
    printButton: {
        color: "#007BFF",
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
    },
    document: {
        color: "#007BFF",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    }


}));


export default (observer(({ component: C, ...rest }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { authStore, memberEnrollmentStore: enrollmentStore, commonStore, userProfileStore, userBillingInfoStore, identityVerificationStore, } = useStores();
    const { allCountries, regionsByCountry } = userProfileStore;
    const { cards, allLoading } = userBillingInfoStore;
    const { currentUser } = authStore;
    const { address } = currentUser;
    const { country } = address;
    const { language } = commonStore;
    const { loadingReferralInfo, loadingCartInfo, loadingAdditionalItems
        , effectiveAdditionalItems, error, creating, activeStep, pages, paymentID
        , renewPrice, renewDiscount
        , personalForm, personalFormSubmitting, signature
        , billingInfoForm, billingInfoFormEdit, billingInfoFormEditSubmitting, billingInfoFormSubmitting
        , cartError, billingAddressFormEdit, billingAddressFormEditSubmitting
        , cart, cartTotal, oneTimeItems, recurringItems
        , enrollFeeWaiverQual
    } = enrollmentStore;
    const { identityVerificationStatus, loadingIdentityVerification, } = identityVerificationStore;

    const classes = useStyles();
    //const [activeStep, setActiveStep] = React.useState("1");
    const [title, setTitle] = React.useState(pages[1]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [refundAcknowledge, setRefundAcknowledge] = useState(false);
    const [subscriptionAcknowledge, setSubscriptionAcknowledge] = useState(false);
    const [complete, setComplete] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [incomeDisclosure, setIncomeDisclosure] = useState(false);
    const [showEditBillingInfoModal, setShowEditBillingInfoModal] = useState(false);
    const [showEditBillingAddressModal, setShowEditBillingAddressModal] = useState(false);
    const [autoRenewal, setAutoRenewal] = useState(false);

    const [addItemModal, setAddItemModal] = useState(null);
    const [showItemDetails, setShowItemDetails] = useState(null);
    const printRef = useRef(0);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    let selectedCard = cards && cards.find(x => x.paymentID === paymentID);

    useEffect(() => {
        void identityVerificationStore.checkIdentityVerification();
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isFailedKyc = React.useMemo(() => {
        return ["CLOSED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    useEffect(() => {
        if (loadingIdentityVerification) {
            return;
        } else {
            void handleEnrollMemberEligible();
        }
    }, [loadingIdentityVerification]);

    const handleEnrollMemberEligible = () => {
        if (!!isFailedKyc) {
            commonStore.showError("We apologize, but your account is not eligible to enroll as a Member. Please contact customer support for more details.");
            history.push(`/profile/help`);
        } else if (!!isUnverified) {
            commonStore.showError("Before enrolling as a member, we need you to complete the Identity Verification process.", 15000);
            history.push(`/wallet/ewallet/add_more_money`);
        }
    };

    useEffect(() => {
        (async () => {
            await userProfileStore.loadAllCountries();
            await userBillingInfoStore.loadAll();
            await userProfileStore.loadRegionsByCountry(country);
        })();

    }, []);

    let links = useMemo(() => {

        if (!country) return {};
        return {
            compensationplan: "https://main.secure.footprint.net/forms/" + country.toLowerCase() + "_compensationplan_" + (language || "en") + ".pdf",
            policiesandprocedures: "https://main.secure.footprint.net/forms/" + country.toLowerCase() + "_policiesandprocedures_" + (language || "en") + ".pdf",
            ids: "https://main.secure.footprint.net/forms/" + country.toLowerCase() + "_ids_" + (language || "en") + ".pdf",
        };

    }, [country, language]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNextStep = () => {
        enrollmentStore.handleNextStep();
    };

    const handlePrevStep = () => {
        enrollmentStore.handlPrevStep();
    };

    const handleFinishEnroll = async () => {
        let dto = {
            cartID: cart.cartID,
            lang: commonStore.language,
            paymentID,
            tosSignature: signature,
            ...(!paymentID ? billingInfoForm.values() : {}),
            ...personalForm.values(),
            autoRenew: autoRenewal,
            hasAgreedToTOS: termsAccepted,
            agreedTOSDate: new Date()
        };

        let result = await enrollmentStore.create(dto);
        if (result)
            setComplete(true);

    };

    const switchLanguage = (lang) => {
        handleClose();
        commonStore.setLanguage(lang, true);
    };


    useEffect(
        () => {
            setTitle(pages[activeStep]);
        }, [activeStep]);


    useEffect(
        () => {
            if (country)
                enrollmentStore.initPersonalInfoForm(country);

            enrollmentStore.initBillingInfoForm(country);
            //enrollmentStore.initBillingAddressForm(country)

        }, [country]);

    useEffect(
        () => {

            if (activeStep === "4" && cart === null) {
                (async () => {
                    await Promise.all([enrollmentStore.loadCart({ language: commonStore.language, country })
                        , enrollmentStore.loadAdditionalItems({ country, lang: commonStore.language })
                        , enrollmentStore.loadEnrollmentWaiverQual()
                    ]);
                })();
            }
        }, [activeStep]);

    useEffect(
        () => {
            (async () => {
                if (cart) {
                    await enrollmentStore.loadCartTotal({ cartID: cart.cartID, lang: commonStore.language });
                }
            })();

        }, [cart, oneTimeItems, recurringItems]);

    const showAddItemModal = (x) => {
        setAddItemModal(x);
    };
    const hideAddItemModal = (x) => {
        setAddItemModal(null);
    };
    const hideItemDetails = (x) => {
        setShowItemDetails(null);
    };

    const addItemToCart = (x) => {
        (async () => {
            await enrollmentStore.addToCart({
                cartID: cart.cartID,
                productID: x.productID,
                lang: commonStore.language,
                qty: 1
            });
            hideAddItemModal();
            await enrollmentStore.refreshItems({ cartID: cart.cartID, country, lang: commonStore.language });
        })();
    };

    //====  Billing Info Edit
    const showBillingInfoModal = () => {
        enrollmentStore.initBillingInfoFormEdit(hideBillingInfoModal);
        setShowEditBillingInfoModal(true);
    };

    const hideBillingInfoModal = () => {
        setShowEditBillingInfoModal(false);
        enrollmentStore.closeBillingInfoFormEdit();
    };


    //====  Billing Address Edit
    const showBillingAddressModal = () => {
        enrollmentStore.initBillingAddressFormEdit(hideBillingAddressModal);
        setShowEditBillingAddressModal(true);

    };

    const hideBillingAddressModal = () => {
        setShowEditBillingAddressModal(false);
        enrollmentStore.closeBillingAddressFormEdit();
    };




    const handleBillingCountryChanged = (newVal) => {
        if (newVal)
            userProfileStore.loadRegionsByCountry(newVal);
    };

    const handlePaymentMethodChanged = (newVal) => {
        enrollmentStore.setPaymentId(newVal);
    };

    const handleSignature = (newVal) => {
        enrollmentStore.setSignature(newVal);
    };



    const handleIconClick = (item) => {
        setShowItemDetails(item);
    };



    const handleToggleItem = (item, showConfirm) => {
        if (item.removed) {
            return addItemToCart(item);
        }
        const action = (async () => {
            await enrollmentStore.removeFromCart({ cartID: cart.cartID, productID: item.productID, lang: commonStore.language });
            await enrollmentStore.refreshItems({ cartID: cart.cartID, country, lang: commonStore.language });
        });
        if (showConfirm)
            commonStore.showConfirm(t("enrollmember:removeconfirm"
                , { cartitem: item.productTitle })
                , t("enrollmember:remove")
                , t("enrollmember:remove")
                , action);
        else
            action();

    };

    const setDebugCountry = (e) => {
        authStore.setCountry(e.target.value);
    };

    const disclosureWarning = useMemo(() => {
        switch (country) {
            case 'BS':
            case 'DO':
            case 'HT':
            case 'JM':
            case 'LC':
            case 'TT':
                return "w8bentext";
            case 'FR':
                return "urssaftext";
            case 'MX':
                return "rfctext";
            default:
                return null;
        }
    }, [country]);

    const PersonalFormControl = useMemo(() => {

        switch (country) {
            case 'US':
                return PersonalFormUS;
            case 'CA':
                return PersonalFormCA;
            case 'MX':
                return PersonalFormMX;
            case 'FR':
                return PersonalFormFR;
            case 'PE':
                return PersonalFormPE;
            case 'EC':
                return PersonalFormEC;
            case 'AU':
                return PersonalFormAU;
            case 'BS':
            case 'DO':
            case 'HT':
            case 'JM':
            case 'LC':
            case 'TT':
                return null;
        }
    }, [country]);

    const requireSubscriptionAcknowledge = useMemo(() => {
        return recurringItems.filter(x => !x.removed).length > 0;
    }, [recurringItems]);

    const subscriptionAcknowledged = useMemo(() => {
        return !requireSubscriptionAcknowledge || subscriptionAcknowledge;

    }, [subscriptionAcknowledge, requireSubscriptionAcknowledge]);

    if (complete)
        return <Container maxWidth={false} disableGutters>
            <Grid container className={classes.root}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <Avatar alt="Logo" src="/lifeapp.png" variant={"rounded"} className={classes.logo} />
                </Grid>
                <Grid container item xs={12} direction="column" alignItems={"center"} justify={"center"} spacing={5}>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h5"}>{t("enrollmember:congratulations")}</Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}> {t("enrollmember:enrollsuccess1")} <a href="https://www.starfishperks.com">starfishperks.com</a> </Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}> {t("enrollmember:enrollsuccess2")} </Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}> {t("enrollmember:enrollsuccess3")} </Typography></Grid>
                    <Grid item>
                        <Button component={"a"} href={`https://www.starfishperks.com`}
                            variant={"contained"}
                            color={"secondary"}>Login</Button>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.header} alignItems={"center"} justify={"center"}>

                </Grid>
            </Grid>
        </Container>;
    if (currentUser.isMember)
        return <Container maxWidth={false} disableGutters>
            <Grid container className={classes.root}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <Avatar alt="Logo" src="/lifeapp.png" variant={"rounded"} className={classes.logo} />
                </Grid>
                <Grid container item xs={12} className={classes.header} alignItems={"center"} justify={"center"}>
                    {t("enrollmember:alreadymember")}
                </Grid>
            </Grid>
        </Container>;


    return <Container maxWidth={false} disableGutters>
        <Grid container className={classes.root}>
            <Grid container item xs={12} md={5} className={classes.navigationWrapper} alignItems={"flex-start"}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <ListItem disableGutters dense>
                        <ListItemAvatar>
                            <Avatar alt="Logo" src="/lifeapp.png" variant={"rounded"} className={classes.logo} />
                        </ListItemAvatar>
                        <div className={classes.referralInfo}>
                            <div><strong>{t("enrollmember:memberenrollmentfor")}</strong></div>

                            <React.Fragment>
                                <div>{currentUser.firstName} {currentUser.lastName}</div>
                            </React.Fragment>

                        </div>
                    </ListItem>
                    <div>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <LanguageIcon /><ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={e => {
                                switchLanguage("en");
                            }}>English</MenuItem>
                            <MenuItem onClick={e => {
                                switchLanguage("es");
                            }}>Español</MenuItem>
                            <MenuItem onClick={e => {
                                switchLanguage("fr");
                            }}>Français</MenuItem>
                        </Menu>
                    </div>
                </Grid>
                <Grid container item xs={12} className={classes.navigation} justify={"center"} direction={"column"}>

                    <div>
                        <Typography variant={"h5"}>
                            {activeStep > 1 && <IconButton onClick={handlePrevStep}><KeyboardArrowLeftIcon /></IconButton>}
                            {t(`enrollmember:${title}`)}
                        </Typography>
                    </div>
                    {error && <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}


                </Grid>
                {process.env.REACT_APP_STAGE === "development" && <Grid container item xs={12} >
                    DEBUG CHANGE COUNTRY:
                    <Select value={country} onChange={setDebugCountry}>
                        <MenuItem value={"US"}>US</MenuItem>
                        <MenuItem value={"CA"}>CAnada</MenuItem>
                        <MenuItem value={"MX"}>MeXico</MenuItem>
                        <MenuItem value={"PE"}>PEru</MenuItem>
                        <MenuItem value={"EC"}>ECuador</MenuItem>
                        <MenuItem value={"FR"}>FRance</MenuItem>
                        <MenuItem value={"BS"}>BS</MenuItem>
                        <MenuItem value={"DO"}>DO</MenuItem>
                        <MenuItem value={"HT"}>HT</MenuItem>
                        <MenuItem value={"JM"}>JM</MenuItem>
                        <MenuItem value={"LC"}>LC</MenuItem>
                        <MenuItem value={"TT"}>TT</MenuItem>
                    </Select>
                </Grid>}
            </Grid>
            <Grid container item xs={12} md={7} className={classes.contentPanel}>
                <TabContext value={activeStep}>
                    <TabPanel value={"1"} className={classes.tabPanel}>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={12} lg={9}>
                                <Paper className={classes.termsTitle}>
                                    {t(`enrollmember:instructionmemberagreement`)}
                                </Paper>
                            </Grid>
                            <Grid container item xs={12} lg={9} justify={"flex-end"} alignItems={"center"}>
                                <a onClick={handlePrint} className={classes.printButton}>{t("enrollmember:printversion")} <PrintIcon /></a>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <div className={classes.temsAndConditions} ref={printRef}>
                                    <strong>{t("enrollmember:termsandconditions")}</strong>
                                    <p>
                                        {country === 'CA' ? t("enrollmember:termsandconditionstext.CA") : t("enrollmember:termsandconditionstext.US")}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormControlLabel control={<Checkbox />}
                                    value={termsAccepted}
                                    checked={termsAccepted}
                                    onChange={() => { setTermsAccepted(!termsAccepted); }}
                                    label={t("enrollmember:iagreetotheseterms")} />
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Button disabled={!termsAccepted} fullWidth onClick={handleNextStep} variant={"contained"}
                                    color="primary">{t("enrollmember:continue")}</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={"2"} className={classes.tabPanel}>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={12} lg={9}>
                                <Paper className={classes.termsTitle}>
                                    {t(`enrollmember:instructionpersonalinfo1`)}
                                </Paper>
                            </Grid>
                            <Grid container item xs={12} lg={9} spacing={2} >
                                <Grid container item xs={12} direction={"column"}>
                                    <a target="_blank" href={links.ids} className={classes.document}><PdfIcon /> {t(`enrollmember:incomedisclosurestatement`)}</a>
                                    <a target="_blank" href={links.ids} className={classes.document}>( <DownloadIcon /> {t(`enrollmember:viewanddownload`)} )</a>
                                </Grid>
                                <Grid container item xs={12} direction={"column"}>
                                    <a target="_blank" href={links.compensationplan} className={classes.document}> <PdfIcon /> {t(`enrollmember:lifemembercompensationplan`)}</a>
                                    <a target="_blank" href={links.compensationplan} className={classes.document}> (<DownloadIcon /> {t(`enrollmember:viewanddownload`)})</a>
                                </Grid>
                                <Grid container item xs={12} direction={"column"}>
                                    <a target="_blank" href={links.policiesandprocedures} className={classes.document}> <PdfIcon /> {t(`enrollmember:lifepoliciesandprocedures`)}</a>
                                    <a target="_blank" href={links.policiesandprocedures} className={classes.document}> (<DownloadIcon /> {t(`enrollmember:viewanddownload`)})</a>
                                </Grid>

                            </Grid>

                            {disclosureWarning && <Grid item xs={12} lg={9}>
                                <Paper className={clsx(classes.termsTitle, classes.termsTitleYellow)}>
                                    {t(`enrollmember:${disclosureWarning}`)}
                                </Paper>
                            </Grid>}

                            <Grid item xs={12} lg={9}>

                                <Typography variant={"subtitle1"}>{t(`enrollmember:drawyoursignature`)}</Typography>
                                <Signature onChange={handleSignature} />
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormControlLabel control={<Checkbox />}
                                    value={incomeDisclosure}
                                    checked={incomeDisclosure}
                                    onChange={() => { setIncomeDisclosure(!incomeDisclosure); }}
                                    label={t("enrollmember:agreetothesedocuments")} />
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Button disabled={!incomeDisclosure || !(signature && signature.length > 10)} fullWidth onClick={handleNextStep} variant={"contained"}
                                    color="primary">{t("enrollmember:continue")}</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={"3"} className={classes.tabPanel}>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={12} lg={9}>
                                <Paper className={classes.termsTitle}>
                                    {t(`enrollmember:instructionpersonalinfo2`)}
                                </Paper>
                            </Grid>
                            <Grid container item xs={12} lg={9} spacing={2} >
                                {(personalForm && PersonalFormControl && (personalForm.country === country)) && <>
                                    <PersonalFormControl form={personalForm} onSubmit={() => {
                                        enrollmentStore.submitPersonalForm();
                                    }} submitting={personalFormSubmitting} allCountries={allCountries} />
                                </>}
                                {!PersonalFormControl && <>
                                    <Grid item xs={12} lg={9}>
                                        <Paper className={clsx(classes.termsTitle, classes.termsTitleYellow)}>
                                            {t(`enrollmember:w8bentext`)}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Button fullWidth onClick={handleNextStep} variant={"contained"}
                                            color="primary">{t("enrollmember:continue")}</Button>
                                    </Grid>
                                </>}
                            </Grid>

                        </Grid>
                    </TabPanel>
                    <TabPanel value={"4"} className={classes.tabPanel}>
                        {allLoading
                            ? <CircularProgress />
                            : <BillingInfoForm existingPayments={cards}
                                translationPrefix={"enrollmember"}
                                allCountries={allCountries}
                                regionsByCountry={regionsByCountry} paymentID={paymentID}
                                handlePaymentMethodChanged={handlePaymentMethodChanged}
                                handleCountryChanged={handleBillingCountryChanged}
                                form={billingInfoForm}
                                onSubmit={() => { enrollmentStore.submitBillingInfoForm(); }}
                                submitting={billingInfoFormSubmitting} />}
                    </TabPanel>
                    <TabPanel value={"5"} className={classes.tabPanel}>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={12} >
                                <Paper className={clsx(classes.termsTitle, classes.termsTitleGreen)}>
                                    {t(`enrollmember:aspartofyourtransition`)}
                                    <br />
                                    <br />
                                    {t(`enrollmember:thefollowingitemswereadded`)}

                                </Paper>
                            </Grid>
                            {billingInfoForm && <Grid container item xs={12} spacing={3}>
                                <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:billinginfo")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                            onClick={showBillingInfoModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {paymentID === 0 && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardtype2")}{billingInfoForm.values().cardType}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardnumber2")}******** {billingInfoForm.values().cardNumber && billingInfoForm.values().cardNumber.substr(billingInfoForm.values().cardNumber.length - 4)}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardexpiration")}{billingInfoForm.values().cardExpMonth}/{billingInfoForm.values().cardExpYear}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cvv")}{billingInfoForm.values().cardCode}</Typography>
                                        </Grid>
                                    </Grid>}
                                    {selectedCard && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardtype2")}{selectedCard.type} *******{selectedCard.last4}</Typography>
                                        </Grid>

                                    </Grid>}
                                </Grid>
                                {paymentID === 0 && <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:billingaddress")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                            onClick={showBillingInfoModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {billingInfoForm && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billFirstName} {billingInfoForm.values().billLastName}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billAddress}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billCity}, {billingInfoForm.values().billRegion}, {billingInfoForm.values().billPostalCode}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billCountry}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>}
                            </Grid>}
                            <Divider />
                            <Grid item xs={12} >
                                {cartError && <Alert variant="filled" severity={"error"}>{t(`enrollment:errors.${cartError}`)}</Alert>}
                            </Grid>
                            <Grid container item xs={12} lg={12} spacing={3}>
                                {(loadingCartInfo || loadingAdditionalItems)
                                    ? <CircularProgress />
                                    : <>
                                        <CartView oneTimeItems={oneTimeItems}
                                            recurringItems={recurringItems}
                                            handleToggleItem={handleToggleItem}
                                            handleAdditionalItemSelect={showAddItemModal}
                                            handleIconClick={handleIconClick}
                                            cartTotal={cartTotal}
                                            autoRenewal={autoRenewal}
                                            setAutoRenewal={setAutoRenewal}
                                            showRenewal={true}
                                            enrollFeeWaiverQual={enrollFeeWaiverQual}
                                            renewPrice={renewPrice}
                                            renewDiscount={renewDiscount}
                                            effectiveAdditionalItems={effectiveAdditionalItems} />

                                    </>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.termsTitle}>
                                    {t(`enrollment:completeenrollmentacknowledge`)}
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox />}
                                    value={refundAcknowledge}
                                    checked={refundAcknowledge}
                                    onChange={() => { setRefundAcknowledge(!refundAcknowledge); }}
                                    label={t("enrollment:refundacknowledgelabel")} />
                                <FormHelperText>{t("enrollment:refundacknowledge")}</FormHelperText>
                            </Grid>

                            {requireSubscriptionAcknowledge && <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox />}
                                    value={subscriptionAcknowledge}
                                    checked={subscriptionAcknowledge}
                                    onChange={() => { setSubscriptionAcknowledge(!subscriptionAcknowledge); }}
                                    label={t("enrollment:subscriptionacknowledgelabel")} />
                                <FormHelperText>{t("enrollment:subscriptionacknowledge")}</FormHelperText>
                            </Grid>}

                            <Grid item xs={12} lg={9}>
                                {creating
                                    ? <CircularProgress />
                                    : <Button onClick={handleFinishEnroll}
                                        disabled={!subscriptionAcknowledged || !refundAcknowledge || (cart ? cart.cartItems : []).length === 0}
                                        fullWidth variant={"contained"}
                                        color="primary">{t("enrollmember:completeenrollment")}</Button>}
                            </Grid>
                        </Grid>

                    </TabPanel>
                </TabContext>
                {loadingReferralInfo && <div className={classes.contentPanelFade}><CircularProgress size={50} /></div>}

            </Grid>
        </Grid>

        {showEditBillingInfoModal && <Dialog maxWidth={"md"} open={true} onClose={hideBillingInfoModal}>
            <DialogContent>
                <BillingInfoForm form={billingInfoFormEdit}
                    allCountries={allCountries}
                    regionsByCountry={regionsByCountry} paymentID={paymentID}
                    handlePaymentMethodChanged={handlePaymentMethodChanged}
                    handleCountryChanged={handleBillingCountryChanged}
                    existingPayments={cards} translationPrefix={"enrollmember"} />
            </DialogContent>
            <DialogActions>
                {billingInfoFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideBillingInfoModal} variant={"contained"}
                            color="secondary">{t("enrollmember:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitBillingInfoEditForm();
                        }} variant={"contained"}
                            color="primary">{t("enrollmember:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {showEditBillingAddressModal && <Dialog maxWidth={"md"} open={true} onClose={hideBillingAddressModal}>
            <DialogContent>
                <BillingAddressForm form={billingAddressFormEdit}
                    allCountries={allCountries}
                    regionsByCountry={regionsByCountry}
                    handleCountryChanged={handleBillingCountryChanged} />
            </DialogContent>
            <DialogActions>
                {billingAddressFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideBillingAddressModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitBillingAddressEditForm();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {addItemModal && <ItemDialog item={addItemModal} hideItemModal={hideAddItemModal} onConfirm={addItemToCart} />}
        {showItemDetails && <ItemDialog item={showItemDetails} hideItemModal={hideItemDetails} />}
    </Container>;

}));
