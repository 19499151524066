import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {isAudio, isBook, isPack, isVideo, isTypeObservable, isVimeo, isVimeoAlbum, isFullDetails} from "../utils/mediaUtils";
import services from "../services";
import {useStores} from "../hooks/use-stores";
import every from "lodash/every";
import {runInAction} from "mobx";
import {joinPath} from "../utils/helpers";


export default observer(({audioPaused, media, currentMediaID, onSelect, onVideoOpen, onVideoAlbumOpen, onOpenBook, onAudioPlay, onAudioPause,  playMatch }) => {

    const history = useHistory();
    const { authStore, libraryStore, seriesStore } = useStores();
    const { freeAudiosForMSBasicSKUs } = seriesStore
    const fetchIdRef = React.useRef(0)

    const fetchMediaDetails = React.useCallback( async (media)=>{
        console.log("fetchMediaType", media )
        const fetchId = ++fetchIdRef.current
        try {
            libraryStore.addDetailsLoadingFor( media.mediaID )
            let result = await services.Library.details(media.mediaID)
            if (fetchId === fetchIdRef.current && result && result[0]) {
                handleMedia( result[0] )
            }
        }
        catch(e){
            if (fetchId === fetchIdRef.current) {
            }
        }
        finally{
            runInAction(() => {
                libraryStore.removeDetailsLoadingFor( media.mediaID )
            })
        }
    })

    const handleMedia = (media)=>{
        if ( media ){
            let prefix ="";
            if ( authStore.subscriptions.hasSuperAppPlus ){
                prefix = "/royalstarfish"
            } else if (  authStore.isStarfish  ){
                prefix = "/starfish"
            }
            if ( media.sku && media.mediaID ) {
                if (media.sku.startsWith("eLTMSSA")) {
                    if (!(authStore.subscriptions.hasLTBasic || authStore.subscriptions.hasLTPremium)) {
                        return history.push(joinPath(prefix, "/members/marketing_systems"))
                    }
                } else if (media.sku.startsWith("eLT")) {
                    if( authStore.subscriptions.hasLTBasic && media.hasPurchased ){
                       // do nothing since we have access
                    }
                    else if (!authStore.subscriptions.hasLTPremium) {
                        return history.push(joinPath(prefix, "/members/marketing_systems"))
                    }
                } else if (media.sku.startsWith("eLTMSPPS")) {
                    if (!authStore.subscriptions.hasLTPremium) {
                        return history.push(joinPath(prefix, "/members/marketing_systems"))
                    }

                } else if (media.sku.startsWith("eMSP")) {
                    if (!authStore.subscriptions.hasLTPremium) {
                        return history.push(joinPath(prefix, "/members/marketing_systems"))
                    }
                } else if (media.seriesValue === "LT" && media.files && media.files.length > 0 && every(media.files, x => !x.mediaURL)) { //PACK under LT
                    if (!authStore.subscriptions.hasLTPremium) {
                        return history.push(joinPath(prefix, "/members/marketing_systems"))
                    }
                } else if (media.sku.startsWith("eRP")) {
                    if (!(authStore.subscriptions.hasSuperAppPlus || authStore.isStarfish))
                        return history.push(joinPath(prefix, "/userprofile/subscriptions"))
                } else if (!authStore.subscriptions.hasSuperAppPlus) {
                    return history.push(joinPath(prefix, "/userprofile/subscriptions"))
                }
            }
        }
        if ( onSelect ){
            onSelect(media);
            return
        }

        if ( isPack(media) ){
            history.push( `/library/packs/${media.mediaID}`)
            return;
        }
        else if ( isVimeoAlbum(media) ) {
            onVideoAlbumOpen(media)
        }
        else if ( isVideo(media)|| isVimeo(media) ) {
            onVideoOpen(media)
        }
        else if ( isAudio(media) ){
            if (currentMediaID === media.mediaID && !audioPaused)
                onAudioPause(media);
            else
                onAudioPlay(media);
        }
        else if ( isBook(media) ){
            onOpenBook(media)
        }
        if ( playMatch && media.seriesValue ){
             history.push( `/library/series/` + media.seriesValue)
         }
        else if ( playMatch  ){
             history.push( `/library`)
         }

    }

    const handleMediaChange = async (media)=>{
        if ( !media ) return
        if ( isTypeObservable ( media) && isFullDetails(media) )
            handleMedia(media)
        else
            fetchMediaDetails(media)

    }
    useEffect(()=>{
        handleMediaChange(media)
    }, [media])


    return <></>
})


