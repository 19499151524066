import {starFishColor} from "../../components/styles";

export function ContentPageStyles(theme) {

    return {
        frame: {
            height: "calc( 100vh - 137px )",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
        },
        imgHolder:{
            display:"flex",
            justifyContent:"center",
        },

        middleImage:{
            width: "100%",
            marginTop: 14,
            marginBottom: 14,
        },
        overlayImage:{
            position: "absolute",
            left:"50%",
            top:"50%",
            transform: "translate(-50%, -50%)",
            maxWidth:"90%",
            maxHeight:"60%"
        },

        content:{
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"flex-start",
            paddingTop: 50,
            paddingBottom: 50,
        },
        textContainer:{
            width:"83%",
            paddingLeft:20,
            paddingRight:20,
            [theme.breakpoints.down(768)]: {
                width:"100%",
            }
        },
        section: {
            paddingTop: 24,
            paddingBottom: 24,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            position: "relative",
        },
        sectionDense:{
            paddingTop: 0,
            paddingBottom: 0,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            position: "relative",
        },
        noBottomMargin:{
            paddingBottom: 0,
            marginBottom: 0,
        },
        noTopMargin:{
            paddingTop: 0,
            marginTop: 0,
        },
        inverse:{
            ...starFishColor(theme, "backgroundColor"),
            color: "white"
        },
        linkColor:{
            ...starFishColor()
        },
        headText:{
            fontFamily: "'Barlow Condensed', 'Arial'",
            paddingTop: 14,
            paddingBottom: 14,
            lineHeight: 1.2,
            fontWeight:"bold",
        },

        headTextNoTopMargin:{
            fontFamily: "'Barlow Condensed', 'Arial'",
            paddingTop: 0,
            paddingBottom: 14,
            lineHeight: 1.2,
            fontWeight:"bold",
        },
        mainText:{
            paddingBottom: 24,
            lineHeight: 1.2,
            [theme.breakpoints.down(768)]: {
                paddingLeft: 5,
                paddingRight: 5,
            }
        },
        condensedText:{
            fontFamily: "'Barlow Condensed', 'Arial'",
            fontSize: "14pt",
            fontWeight: "bold",
        },

        divider:{
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.82)"
        },
    }

}