import React, {useEffect, useMemo, useState} from "react";
import {  observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import {useStores} from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import MediaList from "../components/MediaList";
import SpeakersList from "../components/SpeakersList";
import LibraryTitle from "../components/LibraryTitle";
import {useRouteMatch} from "react-router";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
    root:{
        color:"white",
    },
    margin:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    divider:{
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    colorWhite:{
        color:"white",
    },
    grid: {

        "&:after":{
            content: '""',
            flex: "auto"
        }
    },

    btnSeries:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        color: "white",
        textDecoration:"none"
    },


    seriesIcon: {
        borderRadius: "50%",
        width: "95%"
    },
    lineClamp:{
        margin: "5px 0",
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.5em",
    },

    notchedOutline:{
        borderColor: "white"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    tabPanel:{
        padding:0
    }
}));


const multilangSeries = ["LT","LIFE","WH","LLRC", "EDGE", "FF", "TPDAB" ]

export default observer ( function LibrarySeries ({prefix}) {
    const classes = useStyles();
    const {seriesStore, audioStore, authStore, libraryStore, mediaHandlers } = useStores();
    const params = useParams();
    const match  = useRouteMatch()
    const {seriesId} = params
    const {series } = libraryStore;
    const {books,speakers, packs, audios, videos, sections, mediaType, seriesLoading, error } = seriesStore;
    const {mediaID, audioProgress, audioPaused, canBuy} = audioStore;
    const {loginGuid} = authStore;
    const [languange, setLanguage] = useState("en");

    useEffect(()=>{
        ( async ()=>{
            if ( seriesId ) {
                let serieToload = seriesId
                if ( multilangSeries.includes(seriesId) ){
                    if ( languange === "es" )
                        serieToload +="SP"
                    if ( languange === "fr" )
                        serieToload +="FR"
                }
                await seriesStore.loadSeries( serieToload)

                seriesStore.setMediaType(sections[0] && sections[0].mediaType)
            }
        })()
    },[seriesId, languange])

    const serie = useMemo(() => {
        return series.find( x=>x.seriesValue === seriesId)
    }, [seriesId, series]);

    const handleTabChange =  (event, newValue)=>{
        seriesStore.setMediaType( newValue )
    }


    const effectiveMediaType = mediaType || (sections[0]&& sections[0].mediaType)

    return <div className={classes.root}>
        {error ?  <Alert severity="error">{error}</Alert> : null}

        {seriesLoading  ? <CircularProgress/>
            :<>
                {serie && <LibraryTitle title={serie.seriesName}
                              imageURL={`https://main.secure.footprint.net/public/icon/library/${serie.imageURL || serie.seriesValue}.png`}>
                    {multilangSeries.includes(seriesId)&&
                    <Select
                        label="Language"
                        className={classes.textField}
                        value ={languange}
                        onChange={e=>{setLanguage(e.target.value)}}>
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'fr'}>Français</MenuItem>
                    </Select>}
                </LibraryTitle>}
                {effectiveMediaType && <TabContext value={effectiveMediaType}>
                <AppBar position="static">
                    <Tabs onChange={handleTabChange} value={effectiveMediaType} >
                        {sections.map(x=>{
                            return <Tab key={x.mediaType} value={x.mediaType} label={x.title} selected={x.mediaType===mediaType}/>
                        })}

                    </Tabs>
                </AppBar>
                <TabPanel value={"audio"} classes={{root: classes.tabPanel}} >
                    <MediaList currentMediaID={mediaID}
                               items={audios}
                               onAudioPlay={mediaHandlers.handleAudioPlay}
                               audioPaused = {audioPaused}
                               loginGuid={loginGuid}
                               canBuy={canBuy}
                               audioProgress={audioProgress}
                               onAudioPause={mediaHandlers.handleAudioPause} />
                </TabPanel>
                <TabPanel value={"video"} classes={{root: classes.tabPanel}}>
                    <MediaList items={videos} onVideoOpen={mediaHandlers.handleVideoOpen}/>
                </TabPanel>
                <TabPanel value={"book"} classes={{root: classes.tabPanel}}>
                    <MediaList  items={books} onOpenBook={mediaHandlers.handleBookOpen}/>
                </TabPanel>
                <TabPanel value={"pack"} classes={{root: classes.tabPanel}}>
                    <MediaList items={packs} parentMatch = {match}/>
                </TabPanel>
                <TabPanel value={"speaker"} classes={{root: classes.tabPanel}}>
                    <SpeakersList items={speakers} prefix={prefix}/>
                </TabPanel>
            </TabContext>}

            </>}

    </div>
})